import React, { useEffect, useState } from 'react'
import { Button, Form, FormLabel, InputGroup, Modal } from 'react-bootstrap'
import PrimaryButton from './PrimaryButton'
import axios from '../api/axios'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { setUserDetails } from '../state/userSlice'
import { setAddresses, setShippingAddressId } from '../state/addressSlice'
import { setCartCount, setCartId, setCartItems } from '../state/cartSlice'

const LoginWithOtp = ({ open, setOpen, openPasswordModal, setOpenSignUpModal, getUserData }) => {
    const dispatch = useDispatch();
    const [loginWithOtpData, setLoginWithOtpData] = useState({
        mobile: "",
        otp: ""
    })
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isOtpSending, setIsOtpSending] = useState(false);
    const [countdown, setCountdown] = useState(30);

    const handleLoginWithOtpInputChange = (e) => {
        const { name, value } = e.target
        setLoginWithOtpData((prev) => ({ ...prev, [name]: value }))
    }

    const handleLogin = async (e) => {
        e.preventDefault();

        if (!loginWithOtpData?.mobile?.trim()) {
            toast.error("Mobile number field is required.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return
        }

        if (!/^\d+$/.test(loginWithOtpData?.mobile)) {
            toast.error("Mobile number should contain only digits.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return
        }

        if (loginWithOtpData?.mobile.length !== 10) {
            toast.error("Mobile number should be 10 digits long.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return
        }

        if (!/^[6-9]/.test(loginWithOtpData?.mobile)) {
            toast.error("Mobile must start with 6,7,8 or 9.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return
        }

        setIsLoggedIn(true)

        try {
            const response = await axios.post('v2/auth/signin', loginWithOtpData);
            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
                const token = response?.data?.data?.data?.access_token;
                const { full_name, email, mobile } = response?.data?.data?.data;
                localStorage.setItem('access_token', token);
                dispatch(setUserDetails({ name: full_name, email: email, phoneNumber: mobile }));
                getUserData()
                getAddressList()
                getCartProducts()
                setOpen(false);

                setLoginWithOtpData({
                    mobile: "",
                    otp: ""
                })
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                const form_error = error?.response?.data?.data?.form_error;
                const parser = new DOMParser();
                const doc = parser.parseFromString(form_error, "text/html");

                const errorList = Array.from(doc.querySelectorAll('li')).map(li => li.textContent);

                const errorMessage = (
                    <ul>
                        {errorList.map((error, index) => (
                            <li key={index}>{error}</li>
                        ))}
                    </ul>
                );

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
            if (error?.response?.status === 401) {
                toast.error(error?.response?.data?.data?.message, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setIsLoggedIn(false)
        }
    }

    const sendSignInOtp = async () => {
        if (!loginWithOtpData?.mobile?.trim()) {
            toast.error("Mobile number field is required.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (!/^\d+$/.test(loginWithOtpData?.mobile)) {
            toast.error("Mobile number should contain only digits.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (loginWithOtpData?.mobile.length !== 10) {
            toast.error("Mobile number should be 10 digits long.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (!/^[6-9]/.test(loginWithOtpData?.mobile)) {
            toast.error("Mobile must start with 6,7,8 or 9.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }
        setIsOtpSending(true)
        try {
            const response = await axios.post('v2/auth/signin-otp', { mobile: loginWithOtpData.mobile })
            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } catch (error) {
            if (error?.response?.status === 404) {
                toast.error(error?.response?.data?.data?.message, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
            if (error.response && error.response.status === 400) {
                const form_error = error?.response?.data?.data?.form_error;
                const parser = new DOMParser();

                const doc = parser.parseFromString(form_error, "text/html");

                const errorMessage = doc.body.textContent;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        }
        setTimeout(() => {
            setIsOtpSending(false);
        }, 30000);
        startCountdown();
    }

    const startCountdown = () => {
        setCountdown(30);
        const timer = setInterval(() => {
            setCountdown((prevCount) => prevCount - 1);
        }, 1000);

        setTimeout(() => {
            clearInterval(timer);
        }, 30000);
    };

    const getAddressList = async () => {
        try {
            let accessToken = localStorage.getItem("access_token");
            const headers = {
                Authorization: accessToken,
            };
            const response = await axios.get("v2/address/get", { headers });
            if (response?.data?.status === true) {
                const address = response?.data?.data?.list;
                const firstAddressId = address[0]?.id
                dispatch(setShippingAddressId(firstAddressId))
                dispatch(setAddresses([address]));
                toast.success(response?.data?.data?.message, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        }
    };

    const getCartProducts = async () => {
        try {
            let cartIdFromLocalStorage = localStorage.getItem("cart_id");
            const data = await axios.get(`cart/list?cart=${cartIdFromLocalStorage}`);
            const productsData = data?.data?.data?.cart;
            const cartItems = productsData?.item_list
            dispatch(setCartId(productsData?.id))
            dispatch(setCartItems(cartItems));
            dispatch(setCartCount(cartItems?.length))
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        }
    };

    useEffect(() => {
        if (countdown === 0) {
            clearInterval(startCountdown);
        }
    }, [countdown]);

    return (
        <Modal
            show={open}
            onHide={() => setOpen(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body style={{ background: '#140730', color: 'white', borderRadius: '8px' }}>
                <div className="mb-4">
                    <h3 className="text-center">Login</h3>
                </div>
                <form onSubmit={handleLogin}>
                    <div className="login-body">
                        <div>
                            <FormLabel>Mobile No</FormLabel>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    placeholder="Enter Number"
                                    aria-describedby="basic-addon2"
                                    className="p-2"
                                    name="mobile"
                                    value={loginWithOtpData?.mobile}
                                    onChange={handleLoginWithOtpInputChange}
                                />
                                <Button variant="outline-secondary" id="button-addon2" style={{ color: '#F4861E', background: '#bcbcbd', border: 'none' }} onClick={sendSignInOtp} disabled={isOtpSending || loginWithOtpData?.mobile.length < 10}>

                                    {isOtpSending ?
                                        <p className="text-center white-text my-auto" style={{ color: '#F4861E' }}>{countdown} sec</p> : <p className='my-auto' style={{ color: '#F4861E', fontSize: '14px', }}>Send OTP</p>
                                    }
                                </Button>
                            </InputGroup>

                        </div>
                        <div>
                            <FormLabel>OTP Verification</FormLabel>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    placeholder="Enter OTP"
                                    aria-describedby="basic-addon2"
                                    className="p-2"
                                    name="otp"
                                    value={loginWithOtpData?.otp}
                                    onChange={handleLoginWithOtpInputChange}
                                />
                            </InputGroup>
                            <div>
                                <p className="password-switch-text" style={{ textAlign: 'right' }} onClick={openPasswordModal}>Use Password instead?</p>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-4 mb-4">
                        <PrimaryButton isLoading={isLoggedIn} title="Verify & Sign in" />
                    </div>
                </form>
                <div>
                    <p className="text-center">Don't have an account? <span className="password-switch-text" onClick={() => {
                        setOpen(false)
                        setOpenSignUpModal(true)
                    }}>Sign Up
                    </span> </p>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default LoginWithOtp
