import React from 'react'
import { Navbar, NavbarBrand, NavbarCollapse, NavbarToggle } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';

const SecondNav = () => {
    const pathName = window.location.pathname;
    const navItems = [
        { label: "Benefits", anchor: "benefits" },
        { label: "Ingredients", anchor: "ingredients" },
        { label: "Effects", anchor: "effects" },
        { label: "Awards", anchor: "awards" },
        { label: "FAQ", anchor: "faq" }
    ];

    function scrollToSection(sectionId) {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    }
    return (
        <Navbar
            collapseOnSelect
            expand="lg"
            className="navbar navbar-expand-lg sleepzzz-navbar navbar-light bg-light bg-body-tertiary"
            id="navbar"
        >
            <NavbarToggle
                aria-controls="responsive-navbar-nav"
                className="left-margin"
            />
            <NavbarBrand className="navbar-brand sleepzz-logo color-white sleepzzz-brand-name fw-semibold">
                Sleepz<span style={{ fontSize: "26px" }}>z</span>
                <span style={{ fontSize: "24px" }}>z</span>{" "}
            </NavbarBrand>
            <NavbarCollapse
                id="responsive-navbar-nav"
                className="collapse navbar-collapse justify-content-end"
            >
                <ul className="navbar-nav text-right mx-3 mx-lg-0">
                    {navItems.map((item, index) => (
                        <li className="nav-item d-flex justify-content-end" key={index}>
                            {pathName !== "/" ? (
                                <Link to="/" className="decoration-text-none nav-link color-white" onClick={() => scrollToSection(item.anchor)}>
                                    {item.label}
                                </Link>
                            ) : (
                                <a href={`#${item.anchor}`} className="decoration-text-none nav-link color-white" onClick={() => scrollToSection(item.anchor)}>
                                    {item.label}
                                </a>
                            )}
                        </li>
                    ))}
                    <li className="nav-item d-flex justify-content-end">
                        <NavLink to="/blogs" className="nav-link">
                            <Link to="/blogs" className="decoration-text-none">
                                Blogs
                            </Link>
                        </NavLink>
                    </li>
                    <li className="nav-item d-flex justify-content-end">
                        <NavLink to="/contact" className="nav-link">
                            <Link to="/contact" className="decoration-text-none">
                                Contact
                            </Link>
                        </NavLink>
                    </li>
                </ul>
            </NavbarCollapse>
        </Navbar>
    )
}

export default SecondNav
