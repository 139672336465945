import { ToastContainer } from "react-toastify";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Approutes from "./Approutes";
import Aos from "aos";
import { useEffect } from "react";

function App() {

  useEffect(() => {
    Aos.init()
  }, [])

  return (
    <div className="App">
      <Approutes />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        theme="colored"
        pauseOnHover={false}
      />
    </div>
  );
}

export default App;
