import React from 'react'

const ShippingPolicy = () => {
    return (
        <main className="address-form banner-section">
            <div className="container banner-container">
                <div className="row">
                    <div className="col-md-11 mx-auto">
                        <div className="card">
                            <div className="card-body">
                                <div className="text-center mb-5">
                                    <h2>SHIPPING POLICY</h2>
                                </div>
                                <h5 className="sub-title" style={{ letterSpacing: '10px' }}>
                                    <i>
                                        <b>LAST MODIFIED:</b>
                                        22 AUGUST 2023
                                    </i>
                                </h5>
                                <p className="sub-title mt-5 mb-2">
                                    <u className="medium-font-size"> <i>SHIPPING:</i> </u>
                                </p>
                                <p>
                                    We offer shipping to locations within India only. We currently do not offer international shipping. We use reliable courier partners to deliver your order to your doorstep.

                                    We offer free shipping on all orders.
                                </p>
                                <p className="sub-title mt-4 mb-2">
                                    <u className="medium-font-size"><i>DELIVERY TIME:</i></u>
                                </p>
                                <p className="height-line">
                                    We make every effort to deliver your order as soon as possible. The delivery time depends on your location and the availability of the product. On an average estimated delivery of your product is done within 4 to 5 days.

                                    Please note that the estimated delivery date is not guaranteed and may be affected by factors beyond our control, such as weather conditions, strikes etc.
                                </p>

                                <p className="sub-title mt-4 mb-2">
                                    <u className="medium-font-size"><i>DELIVERY PROCESS:</i></u>
                                </p>
                                <p className="height-line">
                                    Once your order is shipped, you will receive a tracking link from the shipping aggregator to track your order.

                                    If you are not available to receive your order at the time of delivery, the courier partner will call and check with you if you are within close proximity to receive the parcel. If not, the partner will re-attempt to deliver within 2-3 days.

                                    In case the customer is completely unreachable, we will have to cancel the order.
                                </p>

                                <p className="sub-title mt-4 mb-2">
                                    <u className="medium-font-size"><i>DAMAGED OR LOST SHIPMENTS:</i></u>
                                </p>
                                <p className="height-line">
                                    If your order is received in a damaged condition, please contact us immediately at sales@theuplife.in or by phone at +91 98176 56667. We will initiate a claim with the courier partner and arrange for a replacement or refund (refer refund policy).

                                    If your order is lost during shipping, please contact us immediately. We will initiate a claim with the courier partner and arrange for a replacement or refund.
                                </p>
                                <p className="sub-title mt-4 mb-2">
                                    <u className="medium-font-size"><i>CHANGES TO SHIPPING AND DELIVERY POLICY:</i></u>
                                </p>
                                <p className="height-line">
                                    We reserve the right to modify this shipping and delivery policy at any time. We will post any changes on our website and update the "last updated" date at the top of this page.
                                </p>
                                <p className="sub-title mt-4 mb-2">
                                    <u className="medium-font-size"><i>CONTACT US</i></u>
                                </p>
                                <p className="height-line">
                                    If you have any questions or concerns about our terms and
                                    conditions, please contact us at sales@theuplife.in or by
                                    phone at +91 98176 56667.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default ShippingPolicy
