import React, { useState } from 'react'
import { FormControl, FormGroup, FormLabel, Modal } from 'react-bootstrap'
import axios from '../api/axios'
import { toast } from 'react-toastify'
import PrimaryButton from './PrimaryButton'

const ChangePassword = ({ openChangePasswordModal, setOpenChangePasswordModal }) => {
    let accessToken = localStorage.getItem("access_token")
    const [passwordData, setPasswordData] = useState({
        newPassword: "",
        confirmPassword: ""
    });
    const [isLoading, setIsLoading] = useState(false)

    const compareTwoStrings = (str1, str2) => {
        if (str1.length !== str2.length) {
            return false
        }

        for (let i = 0; i < str1.length; i++) {
            if (str1[i] !== str2[i]) {
                return false
            }
        }
        return true
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setPasswordData((prev) => ({ ...prev, [name]: value }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!passwordData?.newPassword?.trim()) {
            toast.error("Password field is required!.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return
        }

        if (!passwordData?.confirmPassword?.trim()) {
            toast.error("Confirm Password field is required!.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return
        }

        if (passwordData?.newPassword) {
            if (passwordData?.newPassword?.length !== passwordData?.confirmPassword?.length) {
                toast.error("Passwords do not match", {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
                return;
            }
            const isPasswordsMatches = compareTwoStrings(passwordData?.newPassword, passwordData?.confirmPassword);

            if (!isPasswordsMatches) {
                toast.error("Passwords do not match", {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
                return;
            }
        }

        setIsLoading(true)

        try {
            const headers = {
                Authorization: accessToken
            }
            const response = await axios.post('v2/account/password', { password: passwordData?.newPassword }, { headers });
            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
                setOpenChangePasswordModal(false)
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                const form_error = error?.response?.data?.data?.form_error;
                const parser = new DOMParser();

                const doc = parser.parseFromString(form_error, "text/html");

                const errorMessage = doc.body.textContent;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        } finally {
            setIsLoading(false)
        }
    }
    return (
        <Modal
            show={openChangePasswordModal}
            onHide={() => setOpenChangePasswordModal(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className='modal-body-background'>
                <div className="mb-4">
                    <h3 className="text-center">
                        Change Password
                    </h3>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="login-body">
                        <div>
                            <FormGroup className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <FormLabel>New Password</FormLabel>
                                <FormControl type="password" value={passwordData?.newPassword} placeholder="Enter New Password" name='newPassword' onChange={handleChange} />
                            </FormGroup>
                        </div>
                        <div>
                            <FormGroup className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <FormLabel>Confirm Password</FormLabel>
                                <FormControl type="password" value={passwordData?.confirmPassword} placeholder="Confirm Password" name='confirmPassword' onChange={handleChange} />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="text-center mt-4 mb-4">
                        <PrimaryButton title="SAVE CHANGES" />
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default ChangePassword
