import React, { useEffect, useState } from 'react'
import { Button, FormControl, FormGroup, FormLabel,  Modal, ModalFooter, ModalHeader, ModalTitle, Spinner, Table } from 'react-bootstrap'
import { toast } from 'react-toastify';
import axios from '../api/axios';

const CancelOrderModal = ({ openModal, setOpenModal, orderId }) => {
    let access_token = localStorage.getItem("access_token");
    const [singleOrderData, setSingleOrderData] = useState({});
    const [cancellationInput, setCancellationInput] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const getSingleOrderData = async (id) => {
        try {
            const headers = {
                Authorization: access_token
            }
            const response = await axios.get(`v2/orders/${id}`, { headers });
            if (response?.data?.status === true) {
                const order = response?.data?.data?.order
                setSingleOrderData(order)
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        }
    }

    const raiseCancellationRequest = async () => {
        if (!cancellationInput.trim()) {
            toast.error("Cancellation reason required", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            })
            return
        }

        setIsLoading(true)
        try {
            const headers = {
                Authorization: access_token
            }

            const data = {
                order_id: orderId,
                reason: cancellationInput
            }

            const response = await axios.post('v2/orders/cancellation/request', data, { headers })

            if (response?.data?.status === true) {
                const successMessage = response?.data?.data?.message
                toast.success(successMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                })
                setCancellationInput("");
                setOpenModal(false)
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
            if (error.response && error?.response?.status === 412) {
                let errorMessage = error?.response?.data?.data?.message;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }

        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getSingleOrderData(orderId)
    }, []);

    return (
        <Modal
            show={openModal}
            onHide={() => setOpenModal(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <ModalTitle>
                <ModalHeader style={{ fontSize: '16px' }}>RAISE CANCELLATION REQUEST</ModalHeader>
            </ModalTitle>
            <Modal.Body>
                <div style={{ height: '255px', overflow: 'auto' }}>
                    {
                        singleOrderData && singleOrderData?.item_list?.map((item) => (
                            <>

                                <div className='d-flex align-items-center'>
                                    {/* LEFT DIV  */}
                                    <div style={{ width: '35%' }}>
                                        <img src={item?.product_image_path} alt="bottle-img" style={{ width: '100%' }} />
                                    </div>

                                    {/* RIGHT DIV  */}
                                    <div style={{ width: '65%' }}>
                                        <Table striped bordered hover>
                                            <tbody>
                                                <tr>
                                                    <td className='small-font-size text-primary'><i>ORDER ID</i></td>
                                                    <td className='small-font-size text-primary'><i>{singleOrderData?.invoice_no}</i></td>
                                                </tr>
                                                <tr>
                                                    <td className='small-font-size'>PRODUCT NAME</td>
                                                    <td className='small-font-size'>{item?.product_name}</td>
                                                </tr>
                                                <tr>
                                                    <td className='small-font-size'>VARIANT</td>
                                                    <td className='small-font-size'>{item?.variant_title}</td>
                                                </tr>
                                                <tr>
                                                    <td className='small-font-size'>QTY</td>
                                                    <td className='small-font-size'>{item?.quantity}</td>
                                                </tr>
                                                <tr>
                                                    <td className='small-font-size'>RATE</td>
                                                    <td className='small-font-size'>₹ {item?.rate}</td>
                                                </tr>
                                            </tbody>

                                        </Table>
                                    </div>
                                </div>
                                {
                                    singleOrderData?.item_list?.length > 2 && <hr />
                                }
                            </>
                        ))
                    }
                </div>
                {
                    singleOrderData?.item_list?.length === 1 && <hr />
                }
                <div className='mt-3'>
                    <FormGroup>
                        <FormLabel>Cancellation Reason</FormLabel>
                        <FormControl as='textarea' rows={5} placeholder='Cancellation Reason' onChange={(e) => setCancellationInput(e.target.value)} />
                    </FormGroup>
                </div>
            </Modal.Body>
            <ModalFooter>
                <Button onClick={raiseCancellationRequest}>
                    {isLoading ? (
                        <span className="d-flex align-items-center justify-content-center gap-2">
                            <Spinner size="sm" /> {" Loading..."}
                        </span>
                    ) : (
                        <span>RAISE REQUEST</span>
                    )}

                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default CancelOrderModal
