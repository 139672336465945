import React, { useState } from "react";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import PrimaryButton from "../../components/PrimaryButton";

const Contact = () => {
  const [inputData, setInputData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    if (!inputData?.name?.trim()) {
      toast.error("Name field is required.", {
        position: "bottom-center",
        autoClose: 2000,
        theme: "colored",
      });
      setIsSubmitted(false);
      return;
    }

    if (!/^[a-zA-Z][a-zA-Z\s\-']*$/.test(inputData?.name)) {
      toast.error("Name field should start with a letter and can only contain letters (both uppercase and lowercase), spaces, hyphens, or apostrophes.", {
        position: "bottom-center",
        autoClose: 2000,
        theme: "colored",
      });
      return;
    }

    if (inputData?.name.length < 3) {
      toast.error("Name should be at least 2 characters long.", {
        position: "bottom-center",
        autoClose: 2000,
        theme: "colored",
      });
      return
    }

    if (/\-{2,}/.test(inputData?.name)) {
      toast.error("Name field cannot contain consecutive hyphens.", {
        position: "bottom-center",
        autoClose: 2000,
        theme: "colored",
      });
      return;
    }

    if (inputData?.email) {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputData?.email)) {
        toast.error("Invalid email address.", {
          position: "bottom-center",
          autoClose: 2000,
          theme: "colored",
        });
        return;
      }
    }

    if (!inputData?.phone?.trim()) {
      toast.error("Mobile number field is required.", {
        position: "bottom-center",
        autoClose: 2000,
        theme: "colored",
      });
      setIsSubmitted(false);
      return;
    }

    if (!/^\d+$/.test(inputData?.phone)) {
      toast.error("Mobile number should contain only digits.", {
        position: "bottom-center",
        autoClose: 2000,
        theme: "colored",
      });
      setIsSubmitted(false);
      return;
    }

    if (inputData?.phone.length !== 10) {
      toast.error("Mobile number should be 10 digits long.", {
        position: "bottom-center",
        autoClose: 2000,
        theme: "colored",
      });
      setIsSubmitted(false);
      return;
    }

    if (!/^[6-9]/.test(inputData?.phone)) {
      toast.error("Mobile must start with 6,7,8 or 9.", {
        position: "bottom-center",
        autoClose: 2000,
        theme: "colored",
      });
      setIsSubmitted(false);
      return;
    }

    if (!inputData?.message?.trim()) {
      toast.error("Message field is required.", {
        position: "bottom-center",
        autoClose: 2000,
        theme: "colored",
      });
      setIsSubmitted(false);
      return;
    }

    try {
      const response = await axios.post("/contact/send", inputData);

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        setInputData({
          name: "",
          email: "",
          phone: "",
          subject: "",
          message: "",
        });
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsSubmitted(false);
    }
  };

  return (
    <section className="py-3">
      <div className="container banner-container">
        <h2 className="text-center white-text fs-1 mb-3">Contact US</h2>
        <div className="row">
          <div className="col-md-6">
            <div className="row mt-4">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <h6 className="text-center">Contact Number</h6>
                    <p className="mt-2 text-center">+91 98176 56667</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <h6 className="text-center">Support Email</h6>
                    <p className="mt-2 text-center">sales@theuplife.in</p>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mt-4">
                <div className="card">
                  <div className="card-body">
                    <h6 className="text-center">Address</h6>
                    <p className="mt-2">
                      1/501, Oberoi Chambers Commercial Premises, <br />
                      New Link Road, Andheri West, <br />
                      Mumbai, Mumbai Suburban, <br />
                      Maharashtra, <br />
                      400053
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <form className="row contact-form" onSubmit={handleSubmit}>
              <div className="col-12 col-md-6  my-2">
                <label className="mb-2 white-text">Full Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Name"
                  value={inputData.name}
                  name="name"
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-md-6 my-2">
                <label className="mb-2 white-text">Email ID (Optional)</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Email"
                  name="email"
                  value={inputData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-md-6 my-2">
                <label className="mb-2 white-text">Mobile Number</label>
                <input
                  type="phone"
                  className="form-control"
                  id="phone"
                  placeholder="Phone"
                  name="phone"
                  value={inputData.phone}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-md-6 my-2">
                <label className="mb-2 white-text">Subject (Optional)</label>
                <input
                  type="text"
                  className="form-control"
                  id="subject"
                  placeholder="Subject"
                  name="subject"
                  value={inputData.subject}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 my-2">
                <label className="mb-2 white-text">Message</label>
                <textarea
                  className="form-control"
                  rows="6"
                  id="message"
                  placeholder="Message"
                  name="message"
                  value={inputData.message}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className="col-12 my-2 text-center">
                <PrimaryButton
                  title="SUBMIT"
                  isLoading={isSubmitted}
                  style={{ marginTop: "15px" }}
                />
              </div>
            </form>
          </div>
          <div className="col-12 col-lg-6 d-md-none text-center my-auto">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3844.91509924441!2d73.8166313747857!3d15.488998285109476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbfc11d2d0a4025%3A0xa4588d73e5eb733a!2sTeam24%20Foods%20and%20Beverages%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1682684585674!5m2!1sen!2sin"
              width="300"
              height="250"
              style={{ border: "none" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="col-12 col-lg-6 d-none d-md-block d-lg-none text-center  mt-4">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3844.91509924441!2d73.8166313747857!3d15.488998285109476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbfc11d2d0a4025%3A0xa4588d73e5eb733a!2sTeam24%20Foods%20and%20Beverages%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1682684585674!5m2!1sen!2sin"
              width="500"
              height="250"
              style={{ border: "none" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
