import React, { useEffect, useState } from 'react'
import { FormControl, FormGroup, FormLabel, Modal } from 'react-bootstrap'
import axios from '../api/axios'
import { toast } from 'react-toastify'
import PrimaryButton from './PrimaryButton'
import { useDispatch } from 'react-redux'
import { setUserDetails } from '../state/userSlice'
import { setAddresses, setShippingAddressId } from '../state/addressSlice'
import { setCartCount, setCartId, setCartItems } from '../state/cartSlice'

const LoginWithPassword = ({ open, setOpen, setOpenLoginModal, getUserData }) => {
    const [inputData, setInputData] = useState({
        mobile: "",
        password: ""
    });
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const dispatch = useDispatch();

    const handleChange = (e) => {
        const { name, value } = e.target
        setInputData((prev) => ({ ...prev, [name]: value }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!inputData?.mobile?.trim()) {
            toast.error("Mobile number field is required.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return
        }

        if (!/^\d+$/.test(inputData?.mobile)) {
            toast.error("Mobile number should contain only digits.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return
        }

        if (inputData?.mobile.length !== 10) {
            toast.error("Mobile number should be 10 digits long.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return
        }

        if (!/^[6-9]/.test(inputData?.mobile)) {
            toast.error("Mobile must start with 6,7,8 or 9.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return
        }

        if (!inputData?.password?.trim()) {
            toast.error("Password field is required.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return
        }

        setIsLoggedIn(true)
        try {
            const response = await axios.post('v2/auth/signin', inputData);
            if (response?.data?.status === true) {
                getUserData()
                const accessToken = response?.data?.data?.data?.access_token;
                const { full_name, email, mobile } = response?.data?.data?.data;
                localStorage.setItem('access_token', accessToken)
                dispatch(setUserDetails({ name: full_name, email: email, phoneNumber: mobile }));
                toast.success(response?.data?.data?.message, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
                getAddressList();
                getCartProducts()
                setOpen(false);
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                toast.error(error?.response?.data?.data?.message, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        } finally {
            setIsLoggedIn(false)
        }
    }

    const getAddressList = async () => {
        try {
            let accessToken = localStorage.getItem("access_token");
            const headers = {
                Authorization: accessToken,
            };
            const response = await axios.get("v2/address/get", { headers });
            if (response?.data?.status === true) {
                const address = response?.data?.data?.list
                const firstAddressId = address[0]?.id
                dispatch(setAddresses([address]));
                dispatch(setShippingAddressId(firstAddressId))
                toast.success(response?.data?.data?.message, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        }
    };

    const getCartProducts = async () => {
        try {
            let cartIdFromLocalStorage = localStorage.getItem("cart_id");
            const data = await axios.get(`cart/list?cart=${cartIdFromLocalStorage}`);
            const productsData = data?.data?.data?.cart;
            const cartItems = productsData?.item_list
            dispatch(setCartId(productsData?.id))
            dispatch(setCartItems(cartItems));
            dispatch(setCartCount(cartItems?.length))
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        }
    };

    useEffect(() => {
        getUserData()
    }, [])

    return (
        <Modal
            show={open}
            onHide={() => setOpen(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body style={{ background: '#140730', color: 'white', borderRadius: '8px' }}>
                <div className="mb-4">
                    <h3 className="text-center">Login</h3>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="login-body">
                        <div>
                            <FormGroup className="mb-3" >
                                <FormLabel>Mobile Number</FormLabel>
                                <FormControl type="phone" placeholder="Enter Mobile Number" name='mobile' value={inputData.mobile} onChange={handleChange} />
                            </FormGroup>
                            <FormGroup className="mb-3" >
                                <FormLabel>Password</FormLabel>
                                <FormControl type="password" placeholder="Enter Password" name='password' value={inputData.password} onChange={handleChange} />
                            </FormGroup>
                            <div className='d-flex justify-content-end'>
                                <p className='password-switch-text' onClick={() => {
                                    setOpen(false)
                                    setOpenLoginModal(true)
                                }}>Use OTP instead?</p>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-4 mb-4">
                        <PrimaryButton title="Verify & Sign in"
                            isLoading={isLoggedIn} />
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default LoginWithPassword
