import React, { useState } from "react";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import { useQuery } from "react-query";

const getBlogsData = async () => {
  const accessToken = localStorage.getItem("access_token");
  const headers = {
    Authorization: accessToken,
  };
  const response = await axios.get("blogs/get", { headers });
  return response.data;
};

const Blogs = () => {
  const [blogsData, setBlogsData] = useState([]);

  const onSuccess = (data) => {
    const blogs = data?.data?.list;
    setBlogsData(blogs);
  }

  const onError = (error) => {
    if (error.response && error.response?.data?.status === false) {
      let errorMessage = error.response.data.error;
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
      });
    }
  }

  const { data } = useQuery({
    queryKey: ['blogsList'],
    queryFn: getBlogsData,
    onSuccess,
    onError
  })

  return (
    <section className="py-4">
      <div className="container">
        <div className="row mb-5">
          {blogsData?.map((blog) => (
            <div className="col-md-12 mb-3 col-lg-4">
              <Link to={`/blogs/${blog?.uri_slug}`}>
                <div className="card shadow">
                  <Image fluid
                    src={blog?.blog_image_path}
                    alt="Sleeping gummies"
                    className="card-img-top"
                    loading="lazy"
                  />
                  <div className="card-body">
                    <h5 className="card-title">
                      {blog?.title
                        ? blog.title.slice(0, 30) +
                        (blog.title.length > 30 ? "........" : "")
                        : ""}
                    </h5>
                    <p className="card-text">- {blog?.author}</p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Blogs;
