import React, { useState } from 'react'
import { Offcanvas, OffcanvasBody, OffcanvasHeader, OffcanvasTitle } from 'react-bootstrap'
import { FaMinus, FaPlus } from 'react-icons/fa6'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { RotatingLines } from 'react-loader-spinner'
import { useDispatch, useSelector } from 'react-redux'
import axios from '../api/axios'
import { toast } from 'react-toastify'
import { setCartCount, setCartId, setCartItems, setIsCartOpen } from '../state/cartSlice'
import { useNavigate } from 'react-router-dom'

const CartComponent = ({ handleClose, }) => {
    let cartIdFromLocalStorage = localStorage.getItem("cart_id");
    let accessToken = localStorage.getItem("access_token");
    const {
        cartItems,
        cartCount,
        isCartOpen,
        offer_price,
        totalCartQty,
        cartId,
        cartMrp,
    } = useSelector((state) => state.cart);
    const [isDeleteSuccess, setIsDeleteSuccess] = useState(false);
    const [cartProducts, setCartProducts] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const deleteCartItem = async (id) => {
        setIsDeleteSuccess(true);
        try {
            const response = await axios.delete("cart/remove", { data: { id } });

            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });

                window.dataLayer.push({ ecommerce: null });
                window.dataLayer.push({
                    event: "remove_from_cart",
                    ecommerce: {
                        currency: "INR",
                        value: cartItems?.grand_total,
                        items: cartItems?.map((item, index) => ({
                            item_id: item?.id,
                            item_name: item?.product_name,
                            affiliation: "UP LIFE",
                            discount: cartItems?.discount_amount,
                            index: index,
                            item_brand: "UP LIFE",
                            item_variant: item?.variant_title,
                            price: item?.offer_price,
                            quantity: item?.quantity,
                        })),
                    },
                });

                getCartProducts();
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        } finally {
            setIsDeleteSuccess(false);
        }
    };

    const updateCartItem = async (cartId, productId, qty) => {
        try {
            const data = {
                cart_id: cartId,
                product: productId,
                quantity: qty,
            };

            const response = await axios.put("cart/update", data);
            if (response?.data?.status === true) {
                getCartProducts();
            }
        } catch (error) {
            if (error?.response && error?.response?.data?.status === false) {
                let errorMessage = error?.response?.data?.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        }
    };

    const getCartProducts = async () => {
        try {
            const response = await axios.get(
                `cart/list?cart=${cartIdFromLocalStorage}`
            );
            if (response?.data?.status === true) {
                const productsData = response?.data?.data?.cart;
                const cartItems = productsData?.item_list;
                setCartProducts(productsData);
                dispatch(setCartId(productsData?.id));
                dispatch(setCartItems(cartItems));
                dispatch(setCartCount(cartItems?.length));

                window.dataLayer.push({ ecommerce: null });
                window.dataLayer.push({
                    event: "add_to_cart",
                    ecommerce: {
                        currency: "INR",
                        value: cartItems?.grand_total,
                        items: cartItems?.map((item, index) => ({
                            item_id: item?.id,
                            item_name: item?.product_name,
                            affiliation: "UP LIFE",
                            discount: cartItems?.discount_amount,
                            index: index,
                            item_brand: "UP LIFE",
                            item_variant: item?.variant_title,
                            price: item?.offer_price,
                            quantity: item?.quantity,
                        })),
                    },
                });
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        }
    };

    const navigateToBuyNow = () => {
        navigate("/sleepzzz-pack-of-1");
        dispatch(setIsCartOpen(false));
    };

    const handleNavigation = () => {
        navigate("/address");
        dispatch(setIsCartOpen(false));
    };

    return (
        <Offcanvas
            show={isCartOpen}
            placement="end"
            onHide={handleClose}
            className="offCanvas-width"
        >
            <OffcanvasHeader closeButton>
                <OffcanvasTitle>Your Cart ( {totalCartQty} ) </OffcanvasTitle>
            </OffcanvasHeader>
            <hr />
            <OffcanvasBody>
                {cartItems && cartItems?.length === 0 ? (
                    <div className="d-flex justify-content-center align-items-center cart-main-div"
                    >
                        <img
                            src="/assets/empty_cart.webp"
                            alt="empty-cart-image"
                            loading="lazy"
                            className="cart-image"
                        />
                    </div>
                ) : isDeleteSuccess ? (
                    <div className="d-flex justify-content-center align-items-center cart-main-div"
                    >
                        <RotatingLines
                            visible={true}
                            height="50"
                            width="50"
                            color="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            ariaLabel="rotating-lines-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                ) : (
                    <div style={{ height: "60%", overflow: "auto" }}>
                        {cartItems?.map((product) => (
                            <div key={product?.id}>
                                <div className="d-flex justify-content-around aligh-items-center gap-2">
                                    <div style={{ width: "32%" }}>
                                        <img
                                            src={product?.product_image}
                                            alt="bottle-image"
                                            width="100%"
                                            loading="lazy"
                                        />
                                    </div>
                                    <div style={{ width: "45%" }} className="mt-3">
                                        <h6 className="fw-bold">{product?.product_name}</h6>
                                        <small>({product?.content_qty} Gummies)</small>
                                        <br />
                                        <small className="text-warning">
                                            <i>{product?.variant_title}</i>
                                        </small>
                                        <br />
                                        <small className="fw-semibold">
                                            Qty: {product?.quantity}{" "}
                                        </small>
                                        <div className="bottom-price" style={{ position: "relative", marginLeft: '0px' }}>
                                            <p
                                                className="fw-semibold"
                                                style={{ position: "relative" }}
                                            >
                                                <span
                                                    style={{ position: "relative", color: "#8c8c8d" }}
                                                >
                                                    ₹ {product?.mrp * product?.quantity}
                                                </span>
                                                <span className="mx-2 fw-bold">
                                                    ₹ {product?.offer_price * product?.quantity}
                                                </span>
                                            </p>
                                        </div>


                                    </div>
                                    <div className="mx-2">
                                        <div
                                            className="mb-4 text-end mt-3 cursor-pointer"
                                            onClick={() => deleteCartItem(product?.id)}
                                        >
                                            <RiDeleteBin6Line size={23} color="red" />
                                        </div>

                                        <div className="d-flex align-items-center justify-content-center gap-2">
                                            <button
                                                disabled={product?.quantity === "1"}
                                                style={{
                                                    border: "1px solid black",
                                                    width: "30px",
                                                    height: "30px",
                                                }}
                                                className="p-1 d-flex align-items-center justify-content-center cursor-pointer"
                                                onClick={() =>
                                                    updateCartItem(
                                                        cartId,
                                                        product.product_id,
                                                        Number(product.quantity) - 1
                                                    )
                                                }
                                            >
                                                <FaMinus />
                                            </button>
                                            <div className="fw-bold fs-6">
                                                {product?.quantity}
                                            </div>
                                            <button
                                                style={{
                                                    border: "1px solid black",
                                                    width: "30px",
                                                    height: "30px",
                                                }}
                                                className="p-1 d-flex align-items-center justify-content-center cursor-pointer"
                                                onClick={() =>
                                                    updateCartItem(
                                                        cartId,
                                                        product.product_id,
                                                        Number(product.quantity) + 1
                                                    )
                                                }
                                            >
                                                <FaPlus />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </div>
                        ))}
                    </div>
                )}

                {cartItems && cartItems?.length === 0 ? (
                    <div className="mt-5" onClick={navigateToBuyNow}>
                        <button className="checkout-btn p-2 cursor-pointer">
                            Explore Gummies
                        </button>
                    </div>
                ) : (
                    <div>
                        <div className="d-flex aligh-items-center justify-content-between mb-3">
                            <div className="fw-semibold text-warning">Total MRP</div>
                            <div className="fw-semibold text-warning">
                                ₹ {cartMrp || 0}
                            </div>
                        </div>
                        <div className="d-flex aligh-items-center justify-content-between mb-3">
                            <div className="fw-semibold text-success">
                                Discount on MRP
                            </div>
                            <div className="fw-semibold text-success">
                                ₹ - {cartMrp - offer_price || 0}
                            </div>
                        </div>
                        <hr />
                        <div className="d-flex aligh-items-center justify-content-between mb-3">
                            <div className="fw-semibold text-primary">Grand Total</div>
                            <div className="fw-semibold text-primary">
                                ₹ {offer_price || 0}
                            </div>
                        </div>
                        <button
                            className="checkout-btn p-2 cursor-pointer"
                            onClick={handleNavigation}
                        >
                            BUY NOW
                        </button>
                    </div>
                )}
            </OffcanvasBody>
        </Offcanvas>
    )
}

export default CartComponent
