import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import axios from '../api/axios';
import { toast } from 'react-toastify';
import PrimaryButton from './PrimaryButton';
import { RotatingLines } from 'react-loader-spinner';

const EditAddress = ({ setOpenEditModal, openEditModal, editingAddressId, getAddressList }) => {
    let accessToken = localStorage.getItem("access_token")
    const [inputData, setInputData] = useState({
        address_id: "",
        name: "",
        mobile: "",
        email: "",
        address_line_1: "",
        address_line_2: "",
        locality: "",
        pincode: "",
        city: "",
        state: ""
    })
    const [isLoading, setIsLoading] = useState(false)
    const [isPincodeLoading, setIsPincodeLoading] = useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target
        setInputData((prev) => ({ ...prev, [name]: value }))
    }

    const getSingleAddress = async () => {
        try {
            const headers = {
                Authorization: accessToken
            }
            const response = await axios.get(`v2/address/${editingAddressId}`, { headers });
            if (response?.data?.status === true) {
                const address = response?.data?.data?.address
                setInputData((prev) => ({
                    ...prev, address_id: editingAddressId, name: address.full_name,
                    mobile: address?.mobile,
                    email: address?.email,
                    address_line_1: address?.street_address_line_1,
                    address_line_2: address?.street_address_line_2,
                    locality: address?.locality,
                    pincode: address?.pincode,
                    city: address?.city,
                    state: address?.state
                }))

                toast.success(response?.data?.data?.message, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        }
    }

    const getPostalCodeDetails = async () => {
        if (!inputData?.pincode.trim()) {
            toast.error("Pincode is required.", {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
            });
            return
        }

        if (!/^\d+$/.test(inputData?.pincode)) {
            toast.error("Pincode should contain only digits.", {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
            });
            return
        }

        if (inputData?.pincode.length !== 6) {
            toast.error("Pincode should be 6 digits long.", {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
            });
            return
        }

        setIsPincodeLoading(true)

        try {
            const response = await axios.get(
                `https://api.postalpincode.in/pincode/${inputData.pincode}`
            );

            if (response?.data[0]?.Status === "Success") {
                const postOffice = response?.data[0]?.PostOffice[0];
                setInputData((prevData) => ({
                    ...prevData,
                    locality: postOffice?.Name || "",
                    city: postOffice?.Division || "",
                    state: postOffice?.State || "",
                }));
            } else if (response?.data[0]?.Status === "Error") {
                toast.error(`${response?.data[0]?.Message} or Invalid Pincode.`, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                setInputData((prevData) => ({
                    ...prevData,
                    locality: "",
                    city: "",
                    state: "",
                }));
            }
        } catch (error) {
            toast.error(error.message, {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
            });
        } finally {
            setIsPincodeLoading(false)
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!inputData?.name?.trim()) {
            toast.error("Name field is required.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return
        }

        if (!/^[a-zA-Z][a-zA-Z\s\-']*$/.test(inputData?.name)) {
            toast.error("Name field should start with a letter and can only contain letters (both uppercase and lowercase), spaces, hyphens, or apostrophes.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (inputData?.name.length < 3) {
            toast.error("Name should be at least 2 characters long.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return
        }

        if (/\-{2,}/.test(inputData?.name)) {
            toast.error("Name field cannot contain consecutive hyphens.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (!inputData?.email.trim()) {
            toast.error("Email field is required!.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputData?.email)) {
            toast.error("Invalid email inputData.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (!inputData?.mobile?.trim()) {
            toast.error("Mobile number field is required.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (!/^\d+$/.test(inputData?.mobile)) {
            toast.error("Mobile number should contain only digits.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (inputData?.mobile.length !== 10) {
            toast.error("Mobile number should be 10 digits long.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (!/^[6-9]/.test(inputData?.mobile)) {
            toast.error("Mobile must start with 6,7,8 or 9.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (!inputData?.address_line_1.trim()) {
            toast.error("H.No / Flat No. / Floor No. / Building Name field is required.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (inputData?.address_line_1.length > 255) {
            toast.error("H.No / Flat No. / Floor No. / Building Name field cannot exceed 255 characters.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        const regex = /^[a-zA-Z0-9\s\-\,\#\.\\/]+$/;

        if (!regex.test(inputData?.address_line_1)) {
            toast.error("H.No / Flat No. / Floor No. / Building Name field contains invalid characters.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (!inputData?.address_line_2.trim()) {
            toast.error("Street Address field is required.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (inputData?.address_line_2.length > 255) {
            toast.error("Street Address field cannot exceed 255 characters.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (!regex.test(inputData?.address_line_2)) {
            toast.error("Street Address field contains invalid characters.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (!inputData?.pincode.trim()) {
            toast.error("Pincode field is required.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (!inputData?.city?.trim()) {
            toast.error("City field is required.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return
        }

        const regexExpression = /^[a-zA-Z\s]+$/

        if (!regexExpression.test(inputData?.city)) {
            toast.error("City field can only contain letters(both uppercase and lowercase), spaces, hyphens or apostrophes", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (inputData?.city.length < 3) {
            toast.error("City should be at least 2 characters long.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return
        }


        if (!inputData?.locality?.trim()) {
            toast.error("Locality field is required.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return
        }

        if (!regexExpression.test(inputData?.locality)) {
            toast.error("Locality field can only contain letters(both uppercase and lowercase), spaces, hyphens or apostrophes", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (inputData?.locality.length < 3) {
            toast.error("Locality should be at least 2 characters long.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return
        }

        if (!inputData?.state?.trim()) {
            toast.error("State field is required.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return
        }

        if (!inputData?.state || !regexExpression.test(inputData?.state)) {
            toast.error("State field can only contain letters (both uppercase and lowercase), spaces, or apostrophes", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (inputData?.state.length < 3) {
            toast.error("State should be at least 2 characters long.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return
        }
        setIsLoading(true)

        try {
            const headers = {
                Authorization: accessToken
            }
            const response = await axios.put('v2/address/update', inputData, { headers })
            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
                setOpenEditModal(false);
                getAddressList()
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
            if (error.response && error.response?.status === 500) {
                let errorMessage = error?.response?.data?.data?.message;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => { getSingleAddress() }, [])

    return (
        <Modal
            show={openEditModal}
            onHide={() => setOpenEditModal(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className='modal-body-background'>
                <div className="row">
                    <div className="col-12 text-center">
                        <h4>UPDATE ADDRESS</h4>
                    </div>
                    <div className="col-12">
                        <form className="row mt-4" onSubmit={handleSubmit}>
                            <div className="col-12 col-md-6 form-group rounded mb-3">
                                <label for="phone">Name</label>
                                <input name='name' value={inputData?.name} type="text" className="form-control rounded-right border-left-0" id="phone" placeholder="Enter Name" onChange={handleChange} required />
                            </div>
                            <div className="col-12 col-md-6 form-group rounded">
                                <label for="phone">Email</label>
                                <input name='email' value={inputData?.email} type="text" className="form-control rounded-right border-left-0" id="phone" placeholder="Enter Email" onChange={handleChange} required />
                            </div>
                            <div className="col-12 col-md-6 form-group rounded mb-3">
                                <label for="address-1">H.No / Flat No. / Floor No. / Building Name
                                </label>
                                <input name='address_line_1' value={inputData?.address_line_1} type="text" className="form-control rounded-right border-left-0" id="address-1" placeholder="H.No / Flat No. / Floor No. / Building Name" onChange={handleChange} required />
                            </div>
                            <div className="col-12 col-md-6 form-group rounded">
                                <label for="address-2">Street address</label>
                                <input name='address_line_2' value={inputData?.address_line_2} type="text" className="form-control rounded-right border-left-0" id="address-2" placeholder="Street address" onChange={handleChange} required />
                            </div>
                            <div className="col-12 col-md-6 form-group rounded mb-3">
                                <label for="phone">Mobile Number</label>
                                <input name='mobile' value={inputData?.mobile} type="phone" className="form-control rounded-right border-left-0" id="phone" placeholder="Enter Mobile Number" onChange={handleChange} required />
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="row">
                                    <div className="col-lg-6 pr-lg-0 form-group rounded" style={{ position: 'relative' }}>
                                        <label for="pincode">Pincode</label>
                                        <input name='pincode' value={inputData?.pincode} type="text" className="form-control rounded-right border-left-0" id="pincode" placeholder="Enter Pincode" onChange={handleChange} onBlur={getPostalCodeDetails} required />
                                        {isPincodeLoading && (
                                            <div style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-0%)' }}>
                                                <RotatingLines
                                                    height="20"
                                                    width="20"
                                                    color="#7B1FA2"
                                                    ariaLabel="circles-loading"
                                                    visible={true}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-lg-6 form-group rounded">
                                        <label for="city">City</label>
                                        <input name='city' value={inputData?.city} type="text" className="form-control rounded-right border-left-0" id="city" placeholder="Enter City" onChange={handleChange} required />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="row">
                                    <div className="col-lg-6 pr-lg-0 form-group rounded">
                                        <label for="locality">Locality</label>
                                        <input name='locality' value={inputData?.locality} type="text" className="form-control rounded-right border-left-0" id="locality" placeholder="Enter Locality" onChange={handleChange} required />
                                    </div>
                                    <div className="col-lg-6 form-group rounded">
                                        <label for="state">State</label>
                                        <input name='state' value={inputData?.state} type="text" className="form-control rounded-right border-left-0" id="state" placeholder="Enter State" onChange={handleChange} required />
                                    </div>
                                </div>
                            </div>
                            <div className="col-8 p-0 mt-3 mx-auto">
                                <PrimaryButton title="SAVE CHANGES" isLoading={isLoading} />
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default EditAddress
