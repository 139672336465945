import React from 'react'

const RefundPolicy = () => {
    return (
        <main className="address-form banner-section">
            <div className="container banner-container">
                <div className="row">
                    <div className="col-md-11 mx-auto">
                        <div className="card">
                            <div className="card-body">
                                <div className="text-center mb-5">
                                    <h2>REFUND POLICY</h2>
                                </div>
                                <h5 className="sub-title" style={{ letterSpacing: '10px' }}>
                                    <i>
                                        <b>LAST MODIFIED:</b>
                                        22 AUGUST 2023
                                    </i>
                                </h5>
                                <p className='mt-3' style={{lineHeight:'30px'}}>
                                    If you are not satisfied with your purchase, you can request a refund within 7-days of receiving your order. We offer a 7-day money-back guarantee for our products. To be eligible for a refund, the following conditions must be met:
                                </p>
                                <ul>
                                    <li>Exactly 7 of the gummies should have been consumed.</li>
                                    <li>The request for refund must be made within 7 days of receiving the order.</li>
                                </ul>
                                <p className="sub-title mt-5 mb-2">
                                    <u className="medium-font-size"> <i>IF YOU MEET THESE CONDITIONS, PLEASE FOLLOW THE STEPS BELOW TO REQUEST A REFUND:</i> </u>
                                </p>
                                <p>
                                    Contact our customer support team by email or phone to initiate the refund process. Please provide your order number, the reason for the return, and your contact information.

                                    Our customer support team will review your request and provide you with instructions on how to return the product.

                                    If we find that the gummies meet these eligibility criteria upon being inspected, we will process the refund to the original payment method used to make the purchase.
                                </p>
                                <p className="sub-title mt-4 mb-2">
                                    <u className="medium-font-size"><i>EXCEPTIONS:</i></u>
                                </p>
                                <p className="height-line">
                                    If you received a damaged or defective product, please contact our customer support team within 48 hours of receiving your order. We will work with you to resolve the issue and provide a replacement or refund if necessary.

                                    If your product is received in a damaged condition, please notify us immediately with photographic evidence of the damage. We will assess the damage and if it meets our criteria, we will refund your payment.

                                    Similarly, if the courier partner is unable to deliver the product to you for any reason, we will also refund your payment.
                                </p>
                                <p className="sub-title mt-4 mb-2">
                                    <u className="medium-font-size"><i>CHANGES:</i></u>
                                </p>
                                <p className="height-line">
                                    We may update this refund policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons. We will post any changes on our website and update the "last updated" date at the top of this page.
                                </p>

                                <p className="sub-title mt-4 mb-2">
                                    <u className="medium-font-size"><i>CONTACT US</i></u>
                                </p>
                                <p className="height-line">
                                    If you have any questions or concerns about our terms and
                                    conditions, please contact us at sales@theuplife.in or by
                                    phone at +91 98176 56667.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default RefundPolicy
