import React, { useEffect, useState } from 'react'
import SelectAddress from '../screens/selectAddress'
import Address from '../screens/address'
import axios from '../api/axios';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setAddresses, setShippingAddressId } from '../state/addressSlice';

const ParentAddressComponent = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { addressCount, isSelectedPage } = useSelector(state => state.address);
    const dispatch = useDispatch();

    const getAddressList = async () => {
        setIsLoading(true)
        try {
            let accessToken = localStorage.getItem("access_token");
            const headers = {
                Authorization: accessToken,
            };
            const response = await axios.get("v2/address/get", { headers });
            if (response?.data?.status === true) {
                const list = response?.data?.data?.list
                const firstAddressId = list[0]?.id
                dispatch(setShippingAddressId(firstAddressId))
                dispatch(setAddresses(list))
                setIsLoading(false)
                toast.success(response?.data?.data?.message, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        }
    };

    useEffect(() => {
        getAddressList()
    }, [])

    if (isLoading) {
        <h1>Loading...</h1>
    }

    return (
        <div>
            {
                isSelectedPage === 1 && addressCount > 0 ? <SelectAddress /> : <Address />
            }
        </div>
    )
}

export default ParentAddressComponent
