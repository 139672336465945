import React, { useEffect } from "react";
import FirstBanner from "../../components/firstBanner/FirstBanner";
import BuyNowSection from "../../components/buyNowSection/BuyNowSection";
import EffectSection from "../../components/effectSection/EffectSection";
import BenefitsSection from "../../components/benefitsSection/BenefitsSection";
import HowItWorksSection from "../../components/howItWorks/HowItWorksSection";
import ComparisonSection from "../../components/comparison/ComparisonSection";
import FAQSection from "../../components/faqSection/FAQSection";
import SecondBuyNow from "../../components/secondBuySection/SecondBuyNow";
import { useDispatch } from "react-redux";
import { setIsSelectedPage } from "../../state/addressSlice";

const Home = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setIsSelectedPage(1))
  }, [])

  return (
    <>
      <FirstBanner />
      <BuyNowSection />
      <EffectSection />
      <BenefitsSection />
      <HowItWorksSection />
      <ComparisonSection />
      <FAQSection />
      <SecondBuyNow />
    </>
  );
};

export default Home;
