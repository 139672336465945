import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    orderId: "",
    orderDetails: null
};

const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        setOrderedId(state, action) {
            state.orderId = action.payload
        },
        setOrderDetails(state, action) {
            state.orderDetails = action.payload
        }
    }
})

export const { setOrderedId, setOrderDetails } = orderSlice.actions;

export default orderSlice.reducer;