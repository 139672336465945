import React, { useEffect, useState } from "react";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { FaEdit, FaPlus } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import EditAddress from "../../components/EditAddress";
import PrimaryButton from "../../components/PrimaryButton";
import { Col, Modal, ModalBody, ModalHeader, ModalTitle, Row } from "react-bootstrap";
import { RotatingLines } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { setUserDetails } from "../../state/userSlice";
import { setAddresses, decrementAddressCount, setShippingAddressId, setIsSelectedPage } from "../../state/addressSlice";
import { useNavigate } from "react-router-dom";
import "./Address.css"

const Address = () => {
    let accessToken = localStorage.getItem("access_token");
    let cart_id = localStorage.getItem("cart_id");
    const [address, setAddress] = useState({
        name: "",
        email: "",
        address_line_1: "",
        address_line_2: "",
        mobile: "",
        pincode: "",
        city: "",
        locality: "",
        state: "",
        signup: true,
        cart: cart_id,
    });
    const [addressList, setAddressList] = useState([]);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [editingAddressId, setEditingAddressId] = useState("");
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch()
    const [isDeleted, setIsDeleted] = useState(false);
    const [deletingAddressId, setDeletingAddressId] = useState("");
    const { cartItems } = useSelector(state => state.cart)
    const navigate = useNavigate();
    const [openAddAddressModal, setOpenAddAddressModal] = useState(false);

    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        setAddress((prev) => ({
            ...prev,
            [name]: name === "signup" ? checked : value,
        }));
    };

    const getUserData = async () => {
        try {
            let accessToken = localStorage.getItem("access_token");
            const headers = {
                Authorization: accessToken,
            };
            const response = await axios.get("v2/account/profile", { headers });
            if (response?.data?.status === true) {
                const userData = response?.data?.data?.profile;
                dispatch(setUserDetails({ name: userData?.full_name, email: userData?.email, phoneNumber: userData?.mobile }))
                toast.success(response?.data?.data?.message, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        }
    };

    const getAddressList = async () => {
        try {
            let accessToken = localStorage.getItem("access_token");
            const headers = {
                Authorization: accessToken,
            };
            const response = await axios.get("v2/address/get", { headers });
            if (response?.data?.status === true) {
                const address = response?.data?.data?.list;
                const firstAddressId = address[0]?.id
                dispatch(setShippingAddressId(firstAddressId))
                setAddressList(address);
                dispatch(setAddresses(address));
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        }
    };

    const getPostalCodeDetails = async () => {

        if (!address?.pincode.trim()) {
            toast.error("Pincode is required.", {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
            });
            return
        }

        if (!/^\d+$/.test(address?.pincode)) {
            toast.error("Pincode should contain only digits.", {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
            });
            return
        }

        if (address?.pincode.length !== 6) {
            toast.error("Pincode should be 6 digits long.", {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
            });
            return
        }

        setLoading(true)

        try {
            const response = await axios.get(
                `https://api.postalpincode.in/pincode/${address.pincode}`
            );

            if (response?.data[0]?.Status === "Success") {
                const postOffice = response?.data[0]?.PostOffice[0];
                setAddress((prevData) => ({
                    ...prevData,
                    locality: postOffice?.Name || "",
                    city: postOffice?.Division || "",
                    state: postOffice?.State || "",
                }));
            } else if (response?.data[0]?.Status === "Error") {
                toast.error(`${response?.data[0]?.Message} or Invalid Pincode.`, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                setAddress((prevData) => ({
                    ...prevData,
                    locality: "",
                    city: "",
                    state: "",
                }));
            }
        } catch (error) {
            toast.error(error.message, {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
            });
        } finally {
            setLoading(false)
        }
    };

    const addAddress = async (e) => {
        e.preventDefault();

        if (!address?.name?.trim()) {
            toast.error("Name field is required.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return
        }

        if (!/^[a-zA-Z][a-zA-Z\s\-']*$/.test(address?.name)) {
            toast.error("Name field should start with a letter and can only contain letters (both uppercase and lowercase), spaces, hyphens, or apostrophes.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (address?.name.length < 3) {
            toast.error("Name should be at least 2 characters long.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return
        }

        if (/\-{2,}/.test(address?.name)) {
            toast.error("Name field cannot contain consecutive hyphens.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (!address?.email.trim()) {
            toast.error("Email field is required!.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(address?.email)) {
            toast.error("Invalid email address.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (!address?.mobile?.trim()) {
            toast.error("Mobile number field is required.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (!/^\d+$/.test(address?.mobile)) {
            toast.error("Mobile number should contain only digits.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (address?.mobile.length !== 10) {
            toast.error("Mobile number should be 10 digits long.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (!/^[6-9]/.test(address?.mobile)) {
            toast.error("Mobile must start with 6,7,8 or 9.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (!address?.address_line_1.trim()) {
            toast.error("H.No / Flat No. / Floor No. / Building Name field is required.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (address?.address_line_1.length > 255) {
            toast.error("H.No / Flat No. / Floor No. / Building Name field cannot exceed 255 characters.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        const regex = /^[a-zA-Z0-9\s\-\,\#\.\\/]+$/;

        if (!regex.test(address?.address_line_1)) {
            toast.error("Address line 1 contains invalid characters.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (!address?.address_line_2.trim()) {
            toast.error("Street Address field is required.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }
        if (address?.address_line_2.length > 255) {
            toast.error("Street address field cannot exceed 255 characters.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (!regex.test(address?.address_line_2)) {
            toast.error("Street address field contains invalid characters.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (!address?.pincode.trim()) {
            toast.error("Pincode field is required.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (!address?.city?.trim()) {
            toast.error("City field is required.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return
        }

        const regexExpression = /^[a-zA-Z\s]+$/

        if (!regexExpression.test(address?.city)) {
            toast.error("City field can only contain letters(both uppercase and lowercase), spaces.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (address?.city.length < 3) {
            toast.error("City should be at least 2 characters long.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return
        }


        if (!address?.locality?.trim()) {
            toast.error("Locality field is required.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return
        }

        if (!regexExpression.test(address?.locality)) {
            toast.error("Locality field can only contain letters(both uppercase and lowercase), spaces.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (address?.locality.length < 3) {
            toast.error("Locality should be at least 2 characters long.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return
        }

        if (!address?.state?.trim()) {
            toast.error("State field is required.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return
        }

        if (!address?.state || !regexExpression.test(address?.state)) {
            toast.error("State field can only contain letters (both uppercase and lowercase), spaces.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (address?.state.length < 3) {
            toast.error("State should be at least 2 characters long.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return
        }

        let accessToken = localStorage.getItem("access_token");

        setIsLoading(true)
        try {
            let payload = {
                name: address.name,
                email: address.email,
                mobile: address.mobile,
                address_line_1: address.address_line_1,
                address_line_2: address.address_line_2,
                locality: address.locality,
                pincode: address.pincode,
                city: address.city,
                state: address.state,
                signup: address.signup,
                cart: address.cart
            };

            if (accessToken) {
                payload.signup = false
            }

            const headers = accessToken ? { Authorization: accessToken } : {};
            const response = await axios.post("v2/address/add", payload, { headers });

            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
                setAddress({
                    name: "",
                    email: "",
                    address_line_1: "",
                    address_line_2: "",
                    mobile: "",
                    pincode: "",
                    city: "",
                    locality: "",
                    state: "",
                    signup: true,
                });
                setConfirmPassword("")
                const accessToken = response?.data?.data?.access_token;
                if (accessToken) {
                    localStorage.setItem("access_token", accessToken);
                }
                getUserData()
                const address = response?.data?.data?.address;
                dispatch(setAddresses([address]));
                dispatch(setShippingAddressId(address?.id))
                dispatch(setIsSelectedPage(1));
                navigate('/address')

                window.dataLayer.push({ ecommerce: null });
                window.dataLayer.push({
                    event: "add_shipping_info",
                    ecommerce: {
                        currency: "INR",
                        value: cartItems?.grand_total,
                        shipping_tier: "Ground",
                        items: cartItems?.map((item, index) => ({
                            item_id: item?.id,
                            item_name: item?.product_name,
                            affiliation: "UP LIFE",
                            discount: cartItems?.discount_amount,
                            index: index,
                            item_brand: "UP LIFE",
                            item_variant: item?.variant_title,
                            price: item?.offer_price,
                            quantity: item?.quantity,
                        })),
                    }
                });
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                const form_error = error?.response?.data?.data?.form_error;
                const parser = new DOMParser();
                const doc = parser.parseFromString(form_error, "text/html");

                const errorList = Array.from(doc.querySelectorAll('li')).map(li => li.textContent);

                const errorMessage = (
                    <ul>
                        {errorList.map((error, index) => (
                            <li key={index}>{error}</li>
                        ))}
                    </ul>
                );

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleOpenEditModal = (id) => {
        setOpenEditModal(true);
        setEditingAddressId(id);
    };

    const handleOpenDeleteModal = (id) => {
        setOpenDeleteModal(true);
        setDeletingAddressId(id);
    };

    const handleDeleteAddress = async (e) => {
        e.preventDefault();
        setIsDeleted(true)
        try {
            const headers = {
                Authorization: accessToken
            }
            const response = await axios.delete(`v2/address/delete?id=${deletingAddressId}`, { headers });
            if (response?.data?.status === true) {
                dispatch(decrementAddressCount())
                getAddressList();
                toast.success(response?.data?.data?.message, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
                setOpenDeleteModal(false);
                setIsDeleted(false);
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        } finally {
            setIsDeleted(false)
        }
    }

    const handleOpenAddressModal = () => {
        setOpenAddAddressModal(true)
    }

    useEffect(() => {
        getAddressList();
        if (accessToken) {
            getUserData()
        }
    }, []);

    return (
        <>
            <div className="d-lg-none">
                <div>
                    <h4 className="text-center m-3 white-text">SAVED ADDRESS</h4>
                </div>
                <div className="d-flex justify-content-end mb-3">
                    <button className="add-btn  d-flex align-items-center gap-1" onClick={handleOpenAddressModal}> <span><FaPlus /> </span>  ADD</button>
                </div>
                {addressList && addressList.length > 0 && (
                    <>
                        <div className="saved-address-main-box">
                            {addressList.map((address) => (
                                <div
                                    key={address.id}
                                    className="shipping-address-box box-border p-3 mb-3"
                                >
                                    <p className="fs-5 fw-semibold">{address?.full_name}</p>
                                    <p className="fs-6 fw-semibold">{address?.email}</p>
                                    <p className="fs-6 ">
                                        {address?.street_address_line_1},{" "}
                                        {address?.street_address_line_2}
                                    </p>
                                    <p className="fs-6">{`${address?.locality}, ${address?.city} - ${address?.pincode}`}</p>
                                    <p className="fs-6fw-semibold">{address?.state}</p>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <p className="fs-6 fw-semibold">
                                                +91 {address?.mobile}
                                            </p>
                                        </div>
                                        <div className="d-flex gap-2 align-items-center">
                                            <FaEdit
                                                color="blue"
                                                size={22}
                                                onClick={() => handleOpenEditModal(address.id)}
                                            />
                                            <RiDeleteBin6Line
                                                color="red"
                                                size={22}
                                                onClick={() => handleOpenDeleteModal(address.id)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                    </>
                )}

            </div>
            <main className="address-form banner-section">
                <div className="container banner-container">
                    <div className="row d-lg-flex d-md-none d-none">
                        <div
                            className={`${addressList.length > 0 ? "col-md-8" : "col-md-10 mx-auto"
                                }`}
                        >
                            <div>
                                <div className="text-center">
                                    <h1>ADD ADDRESS</h1>
                                </div>
                                <form className="row mt-4" onSubmit={addAddress}>
                                    <div className="px-4">
                                        <Row>
                                            <Col className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group rounded">
                                                    <label htmlFor="phone">Name</label>
                                                    <input
                                                        name="name"
                                                        value={address.name}
                                                        type="text"
                                                        className="form-control form-control-lg rounded-right border-left-0"
                                                        id="name"
                                                        placeholder="Enter Name"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group rounded">
                                                    <label htmlFor="phone">Email</label>
                                                    <input
                                                        name="email"
                                                        value={address.email}
                                                        type="text"
                                                        className="form-control form-control-lg rounded-right border-left-0"
                                                        id="email"
                                                        placeholder="Enter Email"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group rounded">
                                                    <label htmlFor="phone">Mobile Number</label>
                                                    <input
                                                        name="mobile"
                                                        value={address.mobile}
                                                        type="phone"
                                                        className="form-control form-control-lg rounded-right border-left-0"
                                                        id="phone"
                                                        placeholder="Enter Mobile Number"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group rounded">
                                                    <label htmlFor="address-1">H.No / Flat No. / Floor No. / Building Name</label>
                                                    <input
                                                        max={255}
                                                        name="address_line_1"
                                                        value={address.address_line_1}
                                                        type="text"
                                                        className="form-control form-control-lg rounded-right border-left-0"
                                                        id="address-1"
                                                        placeholder="H.No / Flat No. / Floor No. / Building Name"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-12 col-md-6 col-lg-6"> <div className="form-group rounded">
                                                <label htmlFor="address-2">Street address</label>
                                                <input
                                                    max={255}
                                                    name="address_line_2"
                                                    value={address.address_line_2}
                                                    type="text"
                                                    className="form-control form-control-lg rounded-right border-left-0"
                                                    id="address-2"
                                                    placeholder="Street address"
                                                    onChange={handleChange}
                                                />
                                            </div></Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <div className="pr-lg-0 form-group rounded" style={{ position: 'relative' }}>
                                                    <label htmlFor="pincode">Pincode</label>
                                                    <input
                                                        name="pincode"
                                                        value={address.pincode}
                                                        type="text"
                                                        className="form-control form-control-lg rounded-right border-left-0"
                                                        id="pincode"
                                                        placeholder="Enter Pincode"
                                                        onChange={handleChange}
                                                        onBlur={getPostalCodeDetails}
                                                    />
                                                    {loading && (
                                                        <div style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-0%)' }}>
                                                            <RotatingLines
                                                                height="20"
                                                                width="20"
                                                                color="#7B1FA2"
                                                                ariaLabel="circles-loading"
                                                                visible={true}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <div className="form-group rounded">
                                                    <label htmlFor="city">City</label>
                                                    <input
                                                        name="city"
                                                        value={address.city}
                                                        type="text"
                                                        className="form-control form-control-lg rounded-right border-left-0"
                                                        id="city"
                                                        placeholder="Enter City"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <div className="pr-lg-0 form-group rounded">
                                                    <label htmlFor="locality">Locality</label>
                                                    <input
                                                        name="locality"
                                                        value={address.locality}
                                                        type="text"
                                                        className="form-control form-control-lg rounded-right border-left-0"
                                                        id="locality"
                                                        placeholder="Enter Locality"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <div className="form-group rounded">
                                                    <label htmlFor="state">State</label>
                                                    <input
                                                        name="state"
                                                        value={address.state}
                                                        type="text"
                                                        className="form-control form-control-lg rounded-right border-left-0"
                                                        id="state"
                                                        placeholder="Enter State"
                                                        onChange={handleChange}
                                                    />
                                                </div></Col>
                                        </Row>
                                        <div className="col-8 p-0 mt-3 mx-auto">
                                            <PrimaryButton
                                                title="PROCEED TO PAY"
                                                isLoading={isLoading}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                        {addressList && addressList.length > 0 && (
                            <>
                                <div className="col-md-4 saved-address-main-box" style={{ overflow: 'auto', height: '400px' }}>
                                    <h4 className="text-center m-3">Saved Addresses</h4>
                                    {addressList.map((address) => (
                                        <div
                                            key={address.id}
                                            className="shipping-address-box box-border w-100 p-3 mb-3"
                                            style={{ display: "inline-block" }}
                                        >
                                            <p className="fs-5 fw-semibold">{address?.full_name}</p>
                                            <p className="fs-6 fw-semibold">{address?.email}</p>
                                            <p className="fs-6 ">
                                                {address?.street_address_line_1},{" "}
                                                {address?.street_address_line_2}
                                            </p>
                                            <p className="fs-6">{`${address?.locality}, ${address?.city} - ${address?.pincode}`}</p>
                                            <p className="fs-6fw-semibold">{address?.state}</p>
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <p className="fs-6 fw-semibold">
                                                        +91 {address?.mobile}
                                                    </p>
                                                </div>
                                                <div className="d-flex gap-2 align-items-center">
                                                    <FaEdit
                                                        color="blue"
                                                        size={22}
                                                        onClick={() => handleOpenEditModal(address.id)}
                                                    />
                                                    <RiDeleteBin6Line
                                                        color="red"
                                                        size={22}
                                                        onClick={() => handleOpenDeleteModal(address.id)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                            </>
                        )}
                    </div>

                </div>
            </main>

            {openEditModal && (
                <EditAddress
                    openEditModal={openEditModal}
                    setOpenEditModal={setOpenEditModal}
                    editingAddressId={editingAddressId}
                    getAddressList={getAddressList}
                />
            )}

            {openDeleteModal && (
                <Modal
                    show={openDeleteModal}
                    onHide={() => setOpenDeleteModal(false)}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Body className='modal-body-background'>
                        <div className="row">
                            <div className="col-12 text-center">
                                <h4>DELETE ADDRESS</h4>
                            </div>
                            <div className="col-12 px-4">
                                <form className="row mt-4" onSubmit={handleDeleteAddress}>
                                    <p className='text-center'>Are you sure, You want to delete this address ?</p>
                                    <div className="col-8 p-4 mt-3 mx-auto">
                                        <PrimaryButton title="CONFIRM" isLoading={isDeleted} />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            )}

            {
                openAddAddressModal && (
                    <Modal show={openAddAddressModal}
                        onHide={() => setOpenAddAddressModal(false)}
                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>

                        <ModalHeader closeButton>
                            <ModalTitle>ADD ADDRESS</ModalTitle>
                        </ModalHeader>
                        <ModalBody>
                            <form className="row mt-4" onSubmit={addAddress}>
                                <div className="px-4">
                                    <Row>
                                        <Col className="mb-3 col-12">
                                            <div className="form-group rounded">
                                                <label className="mb-1" htmlFor="phone">Name</label>
                                                <input
                                                    name="name"
                                                    value={address.name}
                                                    type="text"
                                                    className="form-control form-control-lg rounded-right border-left-0"
                                                    id="name"
                                                    placeholder="Enter Name"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="mb-3 col-12">
                                            <div className="form-group rounded">
                                                <label className="mb-1" htmlFor="phone">Email</label>
                                                <input
                                                    name="email"
                                                    value={address.email}
                                                    type="text"
                                                    className="form-control form-control-lg rounded-right border-left-0"
                                                    id="email"
                                                    placeholder="Enter Email"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="mb-3 col-12">
                                            <div className="form-group rounded">
                                                <label className="mb-1" htmlFor="phone">Mobile Number</label>
                                                <input
                                                    name="mobile"
                                                    value={address.mobile}
                                                    type="phone"
                                                    className="form-control form-control-lg rounded-right border-left-0"
                                                    id="phone"
                                                    placeholder="Mobile Number"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mb-3 col-12">
                                            <div className="form-group rounded">
                                                <label className="mb-1" htmlFor="address-1">H.No / Flat No. / Floor No. / Building Name</label>
                                                <input
                                                    max={255}
                                                    name="address_line_1"
                                                    value={address.address_line_1}
                                                    type="text"
                                                    className="form-control form-control-lg rounded-right border-left-0"
                                                    id="address-1"
                                                    placeholder="H.No / Flat No. / Floor No. / Building Name"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="mb-3 col-12"> <div className="form-group rounded">
                                            <label className="mb-1" htmlFor="address-2">Street address</label>
                                            <input
                                                max={255}
                                                name="address_line_2"
                                                value={address.address_line_2}
                                                type="text"
                                                className="form-control form-control-lg rounded-right border-left-0"
                                                id="address-2"
                                                placeholder="Street address"
                                                onChange={handleChange}
                                            />
                                        </div></Col>
                                    </Row>
                                    <Row>
                                        <Col className="mb-3 col-12">
                                            <div className="pr-lg-0 form-group rounded" style={{ position: 'relative' }}>
                                                <label className="mb-1" htmlFor="pincode">Pincode</label>
                                                <input
                                                    name="pincode"
                                                    value={address.pincode}
                                                    type="text"
                                                    className="form-control form-control-lg rounded-right border-left-0"
                                                    id="pincode"
                                                    placeholder="Enter Pincode"
                                                    onChange={handleChange}
                                                    onBlur={getPostalCodeDetails}
                                                />
                                                {loading && (
                                                    <div style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-0%)' }}>
                                                        <RotatingLines
                                                            height="20"
                                                            width="20"
                                                            color="#7B1FA2"
                                                            ariaLabel="circles-loading"
                                                            visible={true}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </Col>
                                        <Col className="mb-3 col-12">
                                            <div className="form-group rounded">
                                                <label className="mb-1" htmlFor="city">City</label>
                                                <input
                                                    name="city"
                                                    value={address.city}
                                                    type="text"
                                                    className="form-control form-control-lg rounded-right border-left-0"
                                                    id="city"
                                                    placeholder="Enter City"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="mb-3 col-12">
                                            <div className="pr-lg-0 form-group rounded">
                                                <label className="mb-1" htmlFor="locality">Locality</label>
                                                <input
                                                    name="locality"
                                                    value={address.locality}
                                                    type="text"
                                                    className="form-control form-control-lg rounded-right border-left-0"
                                                    id="locality"
                                                    placeholder="Enter Locality"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="mb-3 col-12">
                                            <div className="form-group rounded">
                                                <label className="mb-1" htmlFor="state">State</label>
                                                <input
                                                    name="state"
                                                    value={address.state}
                                                    type="text"
                                                    className="form-control form-control-lg rounded-right border-left-0"
                                                    id="state"
                                                    placeholder="Enter State"
                                                    onChange={handleChange}
                                                />
                                            </div></Col>
                                    </Row>
                                    <div className="col-8 p-0 mt-3 mx-auto">
                                        <PrimaryButton
                                            title="PROCEED TO PAY"
                                            isLoading={isLoading}
                                        />
                                    </div>
                                </div>
                            </form>
                        </ModalBody>
                    </Modal>
                )
            }
        </>
    );
};

export default Address;
