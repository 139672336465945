import React, { useEffect, useState } from 'react'
import { FormControl, FormGroup, FormLabel, Modal } from 'react-bootstrap'
import axios from '../api/axios';
import { toast } from 'react-toastify';
import PrimaryButton from './PrimaryButton';
import { useDispatch } from 'react-redux';
import { setUserDetails } from '../state/userSlice';

const UpdateProfile = ({ openUpdateProfileModal, setOpenUpdateProfileModal }) => {
    let access_token = localStorage.getItem("access_token");
    const [inputData, setInputData] = useState({
        name: "",
        email: "",
        mobile: ""
    })
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch()
    const handleChange = (e) => {
        const { name, value } = e.target
        setInputData((prev) => ({ ...prev, [name]: value }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!inputData?.name?.trim()) {
            toast.error("Name field is required.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return
        }

        if (!inputData?.email?.trim()) {
            toast.error("Email field is required.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return
        }

        if (!/^[a-zA-Z][a-zA-Z\s\-']*$/.test(inputData?.name)) {
            toast.error("Name field should start with a letter and can only contain letters (both uppercase and lowercase), spaces, hyphens, or apostrophes.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (inputData?.name.length < 3) {
            toast.error("Name should be at least 2 characters long.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return
        }

        if (/\-{2,}/.test(inputData?.name)) {
            toast.error("Name field cannot contain consecutive hyphens.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (!inputData?.mobile?.trim()) {
            toast.error("Mobile number field is required.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return
        }

        if (!/^\d+$/.test(inputData?.mobile)) {
            toast.error("Mobile number should contain only digits.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return
        }

        if (inputData?.mobile.length !== 10) {
            toast.error("Mobile number should be 10 digits long.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return
        }

        if (!/^[6-9]/.test(inputData?.mobile)) {
            toast.error("Mobile must start with 6,7,8 or 9.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return
        }

        setIsLoading(true);

        try {
            const headers = {
                Authorization: access_token
            }
            const response = await axios.put('v2/account/update', inputData, { headers });
            if (response?.data?.status === true) {
                const userData = response?.data?.data?.profile
                dispatch(setUserDetails({ name: userData?.full_name, email: userData?.email, phoneNumber: userData?.mobile }));
                toast.success("Account information updated successfully", {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
                getUserData()
                setOpenUpdateProfileModal(false)
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                const form_error = error?.response?.data?.data?.form_error;
                const parser = new DOMParser();

                const doc = parser.parseFromString(form_error, "text/html");

                const errorMessage = doc.body.textContent;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
            if (error?.response?.status === 500) {
                let errorMessage = error.response.data.data?.message;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        } finally {
            setIsLoading(false)
        }
    }

    const getUserData = async () => {
        try {
            let access_token = localStorage.getItem("access_token");
            const headers = {
                Authorization: access_token
            }
            const response = await axios.get('v2/account/profile', { headers });
            const userData = response?.data?.data?.profile
            setInputData((prev) => ({
                ...prev, name: userData?.full_name,
                email: userData?.email,
                mobile: userData?.mobile
            }))
            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        }
    }

    useEffect(() => {
        getUserData()
    }, [])

    return (
        <Modal
            show={openUpdateProfileModal}
            onHide={() => setOpenUpdateProfileModal(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className='modal-body-background'>
                <div className="mb-4">
                    <h3 className="text-center">UPDATE PROFILE</h3>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="login-body">
                        <div>
                            <FormGroup className="mb-3" controlId="exampleForm.ControlInput1">
                                <FormLabel>Name</FormLabel>
                                <FormControl type="text" placeholder="Enter Name" name='name' value={inputData.name} onChange={handleChange} />
                            </FormGroup>
                            <FormGroup className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <FormLabel>Email</FormLabel>
                                <FormControl type="email" placeholder="Enter Email" name='email' value={inputData.email} onChange={handleChange} />
                            </FormGroup>
                            <FormGroup className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <FormLabel>Mobile</FormLabel>
                                <FormControl type="phone" placeholder="Enter Mobile Number" name='mobile' value={inputData.mobile} onChange={handleChange} />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="text-center mt-4 mb-4">
                        <PrimaryButton title="SAVE CHANGES" isLoading={isLoading} />
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default UpdateProfile
