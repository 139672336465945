import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    name: '',
    email: '',
    phoneNumber: '',
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserDetails: (state, action) => {
            state.name = action.payload.name;
            state.email = action.payload.email;
            state.phoneNumber = action.payload.phoneNumber;
        },
    },
});

export const { setUserDetails } = userSlice.actions;
export default userSlice.reducer;
