import React from "react";
import { Link } from "react-router-dom";
import './Footer.css'

const Footer = () => {
    return (
        <footer className="footer">
            <div className="mx-auto">
                <div className="text-center mt-3">
                    <img className="uplife-img" src="assets/uplife.webp" alt="uplife-brand" width='100px' />
                </div>
                <div className="d-flex justify-content-center align-items-center w-100">
                    <Link to='/terms-and-conditions' className="text-white footer-text pt-3 text-decoration-none" >
                        Terms &amp; Conditions
                    </Link>
                    <img className="p-2 p-md-3 p-lg-3 star-img" src="assets/star.webp" alt="" />
                    <Link to='/privacy-policy' className="text-white footer-text pt-3 text-decoration-none" >
                        Privacy Policy
                    </Link>
                    <img className="p-2 p-md-3 p-lg-3 star-img d-none d-lg-block d-md-block" src="assets/star.webp" alt="" />
                    <Link to='/refund-policy' className="text-white footer-text pt-3 text-decoration-none d-none d-lg-block d-md-block" >
                        Refund Policy
                    </Link>
                    <img className="p-2 p-md-3 p-lg-3 star-img d-none d-lg-block d-md-block" src="assets/star.webp" alt="" />
                    <Link to='/shipping-policy' className="text-white footer-text pt-3 text-decoration-none d-none d-lg-block d-md-block" >
                        Shipping Policy
                    </Link>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
