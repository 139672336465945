import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    cartCount: 0,
    cartItems: [],
    cartId: null,
    totalCartQty: 0,
    cartMrp: 0,
    offer_price: 0,
    isCartOpen: false,
    orderId: "",
    orderDetails: {}
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setCartCount(state, action) {
            state.cartCount = action.payload;
        },
        setCartItems(state, action) {
            state.cartItems = [...action.payload];
            state.totalCartQty = state.cartItems.reduce((total, item) => total + parseInt(item.quantity), 0);
            state.cartMrp = state.cartItems.reduce((total, item) => total + (parseInt(item.mrp) * parseInt(item.quantity)), 0)
            state.offer_price = state.cartItems.reduce((total, item) => total + (parseInt(item.offer_price) * parseInt(item.quantity)), 0)

        },
        setCartId(state, action) {
            state.cartId = action.payload;
        },
        setIsCartOpen(state, action) {
            state.isCartOpen = action.payload
        },
        clearCart(state) {
            state.cartCount = 0;
            state.cartItems = [];
            state.cartId = null;
            state.totalCartQty = 0;
            state.cartMrp = 0;
            state.offer_price = 0;
            state.isCartOpen = false
        },

    },
});

export const { setCartCount, setCartItems, setCartId, clearCart, setIsCartOpen } = cartSlice.actions;

export default cartSlice.reducer;
