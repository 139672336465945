import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addresses: [],
    addressCount: 0,
    shippingAddressId: "",
    isSelectedPage: 1
}

const addressSlice = createSlice({
    name: 'address',
    initialState,
    reducers: {
        setAddresses(state, action) {
            state.addresses = action.payload;
            state.addressCount = action.payload.length;
        },
        setShippingAddressId(state, action) {
            state.shippingAddressId = action.payload
        },
        setAddressCount(state, action) {
            state.addressCount = action.payload
        },
        incrementAddressCount(state) {
            state.addressCount++;
        },
        decrementAddressCount(state) {
            if (state.addressCount > 0) {
                state.addressCount--;
            }
        },
        setIsSelectedPage(state, action) {
            state.isSelectedPage = action.payload
        },
        clearAddresses(state) {
            state.addresses = [];
            state.addressCount = 0
        }
    },
});

export const { setAddresses, incrementAddressCount, decrementAddressCount, clearAddresses, setShippingAddressId, setAddressCount, setIsSelectedPage } = addressSlice.actions;

export default addressSlice.reducer;