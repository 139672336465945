import React from 'react';
import './FirstBanner.css';

const FirstBanner = () => {
  return (
    <section className="banner-section" id="banner-section">
      <div className="container-fluid banner-container">
        <div className="row">
          <div className="col-12 col-md-6 first-banner-block">
            <h1 className="first-heading">Tired of tossing & turning at night, unable to fall asleep?</h1>
          </div>
          <div className="col-12 col-md-6 d-none d-md-block second-banner-block">
            <h1>Incomplete sleep making you drowsy at work?</h1>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(FirstBanner);
