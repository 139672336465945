import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsCart3 } from "react-icons/bs";
import {
    Badge,
    Button,
    Container,
    Dropdown,
    Form,
    FormControl,
    FormGroup,
    FormLabel,
    Image,
    InputGroup,
    Modal,
    Nav,
    Navbar,
} from "react-bootstrap";
import "./Navbar.css";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import UpdateProfile from "../UpdateProfile";
import LoginWithPassword from "../LoginWithPassword";
import ChangePassword from "../ChangePassword";
import { MdOutlinePassword } from "react-icons/md";
import PrimaryButton from "../PrimaryButton";
import LoginWithOtp from "../LoginWithOtp";
import { useDispatch, useSelector } from "react-redux";
import { setUserDetails } from "../../state/userSlice";
import {
    clearCart,
    setIsCartOpen,
} from "../../state/cartSlice";
import {
    clearAddresses,
    setAddresses,
    setShippingAddressId,
} from "../../state/addressSlice";
import SecondNav from "./SecondNav";
import CartComponent from "../CartComponent";

const NavbarComponent = () => {
    const userDetails = useSelector((state) => state.user);
    const {
        cartCount,
    } = useSelector((state) => state.cart);
    let accessToken = localStorage.getItem("access_token");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [openLoginModal, setOpenLoginModal] = useState(false);
    const [openSignUpModal, setOpenSignUpModal] = useState(false);
    const [signUpData, setSignUpData] = useState({
        name: "",
        mobile: null,
        email: "",
        password: "",
        otp: null,
    });
    const [openUpdateProfileModal, setOpenUpdateProfileModal] = useState(false);
    const [openLoginWithPasswordModal, setopenLoginWithPasswordModal] =
        useState(false);
    const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
    const [isSignnedUp, setIsSignnedUp] = useState(false);
    const [isOtpSending, setIsOtpSending] = useState(false);
    const [countdown, setCountdown] = useState(30);
    const [showDropdown, setShowDropdown] = useState(false);

    const handleDropdownClick = (action) => {
        action();
        setShowDropdown(false);
    };

    const handleShow = () => {
        dispatch(setIsCartOpen(true));
    };

    const handleClose = () => dispatch(setIsCartOpen(false));

    const handleSignUpInputChange = (e) => {
        const { name, value } = e.target;
        setSignUpData((prev) => ({ ...prev, [name]: value }));
    };

    const sendSignUpOtp = async () => {
        if (!signUpData?.mobile?.trim()) {
            toast.error("Mobile number field is required.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (!/^\d+$/.test(signUpData?.mobile)) {
            toast.error("Mobile number should contain only digits.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (signUpData?.mobile?.length !== 10) {
            toast.error("Mobile number should be 10 digits long.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (!/^[6-9]/.test(signUpData?.mobile)) {
            toast.error("Mobile must start with 6,7,8 or 9.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }
        setIsOtpSending(true);
        try {
            const response = await axios.post("v2/auth/otp", {
                mobile: signUpData.mobile,
            });
            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "bottom-center",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                const form_error = error?.response?.data?.data?.form_error;
                const parser = new DOMParser();

                const doc = parser.parseFromString(form_error, "text/html");

                const errorMessage = doc.body.textContent;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        }
        setTimeout(() => {
            setIsOtpSending(false);
        }, 30000);
        startCountdown();
    };

    const startCountdown = () => {
        setCountdown(30);
        const timer = setInterval(() => {
            setCountdown((prevCount) => prevCount - 1);
        }, 1000);

        setTimeout(() => {
            clearInterval(timer);
        }, 30000);
    };

    const createNewUser = async (e) => {
        e.preventDefault();
        if (!signUpData?.name?.trim()) {
            toast.error("Name field is required.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (!/^[a-zA-Z][a-zA-Z\s\-']*$/.test(signUpData?.name)) {
            toast.error(
                "Name field should start with a letter and can only contain letters (both uppercase and lowercase), spaces, hyphens, or apostrophes.",
                {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                }
            );
            return;
        }

        if (signUpData?.name?.length < 3) {
            toast.error("Name should be at least 2 characters long.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (/\-{2,}/.test(signUpData?.name)) {
            toast.error("Name field cannot contain consecutive hyphens.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (!signUpData?.mobile?.trim()) {
            toast.error("Mobile number field is required.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (!/^\d+$/.test(signUpData?.mobile)) {
            toast.error("Mobile number should contain only digits.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (signUpData?.mobile?.length !== 10) {
            toast.error("Mobile number should be 10 digits long.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }

        if (!/^[6-9]/.test(signUpData?.mobile)) {
            toast.error("Mobile must start with 6,7,8 or 9.", {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
            return;
        }
        if (signUpData?.email) {
            if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(signUpData?.email)) {
                toast.error("Invalid email address.", {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
                return;
            }
        }
        setIsSignnedUp(true);
        try {
            const response = await axios.post("v2/auth/signup", signUpData);
            if (response?.data?.status === true) {
                const accessToken = response?.data?.data?.data?.access_token;
                const { full_name, email, mobile } = response?.data?.data?.data;
                localStorage.setItem("access_token", accessToken);
                dispatch(
                    setUserDetails({ name: full_name, email: email, phoneNumber: mobile })
                );
                toast.success(response?.data?.data?.message, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
                setSignUpData({
                    name: "",
                    mobile: null,
                    email: "",
                    password: "",
                    otp: null,
                });
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                const form_error = error?.response?.data?.data?.form_error;
                const parser = new DOMParser();
                const doc = parser.parseFromString(form_error, "text/html");

                const errorList = Array.from(doc.querySelectorAll("li")).map(
                    (li) => li.textContent
                );

                const errorMessage = (
                    <ul>
                        {errorList.map((error, index) => (
                            <li key={index}>{error}</li>
                        ))}
                    </ul>
                );

                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
            if (error.response && error.response.status === 401) {
                let errorMessage = error.response.data.data.message;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        } finally {
            setIsSignnedUp(false);
        }
    };

    const handleLogOut = () => {
        navigate("/");
        toast.success("You logged out successfully", {
            position: "bottom-center",
            autoClose: 2000,
            theme: "colored",
        });
        localStorage.clear();
        dispatch(clearAddresses());
        dispatch(clearCart());
    };

    const handleOpenLoginWithPassword = () => {
        setOpenLoginModal(false);
        setopenLoginWithPasswordModal(true);
    };

    const getUserData = async () => {
        try {
            let accessToken = localStorage.getItem("access_token");
            const headers = {
                Authorization: accessToken,
            };
            const response = await axios.get("v2/account/profile", { headers });
            if (response?.data?.status === true) {
                const userData = response?.data?.data?.profile;
                dispatch(
                    setUserDetails({
                        name: userData?.full_name,
                        email: userData?.email,
                        phoneNumber: userData?.mobile,
                    })
                );
                getAddressList();
                toast.success(response?.data?.data?.message, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        }
    };

    const getAddressList = async () => {
        try {
            let accessToken = localStorage.getItem("access_token");
            const headers = {
                Authorization: accessToken,
            };
            const response = await axios.get("v2/address/get", { headers });
            if (response?.data?.status === true) {
                const address = response?.data?.data?.list;
                const firstAddressId = address[0]?.id;
                dispatch(setShippingAddressId(firstAddressId));
                dispatch(setAddresses([address]));
                toast.success(response?.data?.data?.message, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
            }
        }
    };

    const goToAddressPage = () => {
        navigate("/my-address");
    };

    const navigateToHome = () => {
        navigate("/");
    };

    const handleOpenLoginModal = () => {
        setOpenLoginModal(true);
    };

    const switchToLogin = () => {
        setOpenLoginModal(true);
        setOpenSignUpModal(false);
    };

    const handleSignUpModalClose = () => {
        setOpenSignUpModal(false);
    };

    const toggleDropDown = () => {
        setShowDropdown(!showDropdown)
    }

    const handleOpenUpdateProfileModal = () => {
        setOpenUpdateProfileModal(true)
    }

    const handleOpenChangePasswordModal = () => {
        setOpenChangePasswordModal(true)
    }

    const navigateToOrder = () => {
        navigate("/orders")
    }

    useEffect(() => {
        getUserData();
        getAddressList();
    }, []);

    useEffect(() => {
        if (countdown === 0) {
            clearInterval(startCountdown);
        }
    }, [countdown]);

    const renderDropdownItems = useMemo(() => (
        <>
            <a className="dropdown-item text-center">
                <img src="/assets/user-icon.webp" className="user-icon" alt="user-icon" loading="lazy" />
                <div className="d-inline-block text-start">
                    <span className="d-block">{userDetails?.name}</span>
                    <small className="d-block">{userDetails?.email}</small>
                    <small className="d-block">+91 {userDetails?.phoneNumber}</small>
                </div>
            </a>
            <a className="dropdown-item" onClick={() => handleDropdownClick(handleOpenUpdateProfileModal)}>
                <img src="/assets/user-icon.webp" className="menu-icon menu-user-icon" alt="user-icon" loading="lazy" />
                <div className="d-inline-block">
                    <span>Profile</span>
                    <small className="d-block">Change or add profile details</small>
                </div>
                <i className="fa fa-angle-right" aria-hidden="true"></i>
            </a>
            <a className="dropdown-item" onClick={() => handleDropdownClick(goToAddressPage)}>
                <img src="/assets/location-icon.webp" className="menu-icon" alt="location-icon" loading="lazy" />
                <div className="d-inline-block">
                    <span>Addresses</span>
                    <small className="d-block">Change, add, delete addresses</small>
                </div>
                <i className="fa fa-angle-right" aria-hidden="true"></i>
            </a>
            <a className="dropdown-item" onClick={() => handleDropdownClick(handleOpenChangePasswordModal)}>
                <MdOutlinePassword size={20} className="menu-icon" />
                <div className="d-inline-block">
                    <span>Change Password</span>
                    <small className="d-block">Update Password</small>
                </div>
                <i className="fa fa-angle-right" aria-hidden="true"></i>
            </a>
            <a className="dropdown-item" onClick={() => handleDropdownClick(navigateToOrder)}>
                <img src="/assets/cart-alt-icon.webp" className="menu-icon" alt="cart-alt-icon" loading="lazy" />
                <div className="d-inline-block">
                    <span>Order History</span>
                    <small className="d-block">Check order history and status</small>
                </div>
                <i className="fa fa-angle-right" aria-hidden="true"></i>
            </a>
            <a className="dropdown-item" onClick={() => handleDropdownClick(handleLogOut)}>
                <img src="/assets/exit-icon.webp" className="menu-icon" alt="exit-icon" loading="lazy" />
                <div className="d-inline-block">
                    <span>Logout</span>
                    <small className="d-block">Log out of this device</small>
                </div>
                <i className="fa fa-angle-right" aria-hidden="true"></i>
            </a>
        </>
    ), [userDetails, handleDropdownClick, handleOpenUpdateProfileModal, goToAddressPage, handleOpenChangePasswordModal, navigateToOrder, handleLogOut]);


    return (
        <>
            <header>
                <Navbar expand="lg" className="bg-body-tertiary uplife-navbar">
                    <Container>
                        <div className="d-lg-block d-none" style={{ width: "43%" }}></div>
                        <Navbar.Brand className="cursor-pointer" onClick={navigateToHome}>
                            <Image
                                src="/assets/uplife.webp"
                                alt="brand-logo"
                                className="brand-img"
                                loading="lazy"
                                width="160px"
                            />
                        </Navbar.Brand>
                        <Nav className="ms-auto">
                            {accessToken ? (
                                <>
                                    <Dropdown
                                        show={showDropdown}
                                        onToggle={toggleDropDown}
                                    >
                                        <Dropdown.Toggle
                                            id="dropdown-basic"
                                            className="text-white dropdown-toggle"
                                        >
                                            <img
                                                src="/assets/user-alt-icon.webp"
                                                alt="profile-icon"
                                                loading="lazy"
                                            />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="dropdown-menu">
                                            {renderDropdownItems}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </>
                            ) : (
                                <button
                                    className="login-btn mx-lg-2 mx-md-2 mx-2"
                                    onClick={handleOpenLoginModal}
                                >
                                    Log In
                                </button>
                            )}
                        </Nav>
                        <div
                            onClick={handleShow}
                            className="pointer-cursor "
                            style={{ position: "relative", display: "inline-block" }}
                        >
                            <BsCart3
                                className="cart-icon cart-icon-style"
                                size={25}
                            />

                            <Badge
                                className="cart-count"
                                bg="danger"
                            >
                                {cartCount}
                            </Badge>
                        </div>
                    </Container>
                </Navbar>

                <CartComponent handleClose={handleClose} />

                <SecondNav />
            </header>

            {openLoginModal && (
                <LoginWithOtp
                    open={openLoginModal}
                    setOpen={setOpenLoginModal}
                    openPasswordModal={handleOpenLoginWithPassword}
                    setOpenSignUpModal={setOpenSignUpModal}
                    getUserData={getUserData}
                />
            )}

            {openSignUpModal && (
                <Modal
                    show={openSignUpModal}
                    onHide={handleSignUpModalClose}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Body
                        style={{
                            background: "#140730",
                            color: "white",
                            borderRadius: "8px",
                        }}
                    >
                        <div className="mb-4">
                            <h3 className="text-center">Create Account</h3>
                        </div>
                        <form onSubmit={createNewUser}>
                            <div className="login-body">
                                <div>
                                    <FormGroup className="mb-2">
                                        <FormLabel>Name</FormLabel>
                                        <FormControl
                                            placeholder="Enter Name"
                                            className="p-2"
                                            name="name"
                                            value={signUpData?.name}
                                            onChange={handleSignUpInputChange}
                                        />
                                    </FormGroup>
                                </div>
                                <div>
                                    <FormLabel>Mobile No</FormLabel>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="phone"
                                            placeholder="Enter Number"
                                            aria-describedby="basic-addon2"
                                            className="p-2"
                                            name="mobile"
                                            value={signUpData?.mobile}
                                            onChange={handleSignUpInputChange}
                                        />
                                        <Button
                                            variant="outline-secondary"
                                            id="button-addon2"
                                            style={{
                                                color: "#F4861E",
                                                background: "#bcbcbd",
                                                border: "none",
                                            }}
                                            onClick={sendSignUpOtp}
                                            disabled={isOtpSending || signUpData?.mobile?.length < 10}
                                        >
                                            {isOtpSending ? (
                                                <p
                                                    className="text-center white-text my-auto"
                                                    style={{ color: "#F4861E" }}
                                                >
                                                    {countdown} sec
                                                </p>
                                            ) : (
                                                <p
                                                    className="my-auto"
                                                    style={{ color: "#F4861E", fontSize: "14px" }}
                                                >
                                                    Send OTP
                                                </p>
                                            )}
                                        </Button>
                                    </InputGroup>
                                </div>
                                <div>
                                    <FormLabel>Enter OTP</FormLabel>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            placeholder="Enter OTP"
                                            aria-describedby="basic-addon2"
                                            className="p-2"
                                            name="otp"
                                            value={signUpData?.otp}
                                            onChange={handleSignUpInputChange}
                                        />
                                    </InputGroup>
                                    <div>
                                        <p
                                            className="password-switch-text"
                                            style={{ textAlign: "right" }}
                                            onClick={sendSignUpOtp}
                                        >
                                            Resend OTP
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <FormGroup className="mb-2">
                                        <FormLabel>Add Password</FormLabel>
                                        <FormControl
                                            placeholder="Enter Password"
                                            type="password"
                                            className="p-2"
                                            name="password"
                                            value={signUpData?.password}
                                            onChange={handleSignUpInputChange}
                                        />
                                    </FormGroup>
                                </div>
                                <div style={{ marginTop: "12px" }}>
                                    <FormGroup className="mb-2 mt-2">
                                        <FormLabel>Enter Email (Optional)</FormLabel>
                                        <FormControl
                                            placeholder="Enter Email"
                                            className="p-2"
                                            name="email"
                                            value={signUpData?.email}
                                            onChange={handleSignUpInputChange}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="text-center mt-4 mb-4">
                                <PrimaryButton title="SIGN UP" isLoading={isSignnedUp} />
                            </div>
                        </form>
                        <div>
                            <p className="text-center">
                                Have an account?{" "}
                                <span
                                    className="password-switch-text"
                                    onClick={switchToLogin}
                                >
                                    Login Instead
                                </span>{" "}
                            </p>
                        </div>
                    </Modal.Body>
                </Modal>
            )}

            {openUpdateProfileModal && (
                <UpdateProfile
                    openUpdateProfileModal={openUpdateProfileModal}
                    setOpenUpdateProfileModal={setOpenUpdateProfileModal}
                />
            )}

            {openLoginWithPasswordModal && (
                <LoginWithPassword
                    open={openLoginWithPasswordModal}
                    setOpen={setopenLoginWithPasswordModal}
                    setOpenLoginModal={setOpenLoginModal}
                    getUserData={getUserData}
                    getAddressList={getAddressList}
                />
            )}

            {openChangePasswordModal && (
                <ChangePassword
                    openChangePasswordModal={openChangePasswordModal}
                    setOpenChangePasswordModal={setOpenChangePasswordModal}
                />
            )}
        </>
    );
};

export default NavbarComponent;
