export const loadScript = (src) => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => {
            resolve();
        };
        script.onerror = (error) => {
            reject(error);
        };
        document.body.appendChild(script);
    });
};
