import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaPlus } from "react-icons/fa6";
import { loadScript } from "../../utils/razorpay";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import HorizontalScroll from "../../components/HorizontalScroll";
import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalHeader,
  ModalTitle,
  Row,
} from "react-bootstrap";
import PrimaryButton from "../../components/PrimaryButton";
import { Puff, RotatingLines } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { clearCart, setCartItems } from "../../state/cartSlice";
import { setOrderDetails, setOrderedId } from "../../state/orderSlice";
import { setAddresses, setShippingAddressId } from "../../state/addressSlice";
import "./SelectAddress.css"

const SelectAddress = () => {
  let accessToken = localStorage.getItem("access_token");
  let cartIdFromLocalStorage = localStorage.getItem("cart_id");
  const { shippingAddressId } = useSelector((state) => state.address);
  const { cartItems, totalCartQty, cartMrp, offer_price } = useSelector(
    (state) => state.cart
  );
  const [loading, setLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState("");
  const [cartProducts, setCartProducts] = useState([]);
  const navigate = useNavigate();
  const [addressList, setAddressList] = useState([]);
  const [openAddressModal, setOpenAddressModal] = useState(false);
  const [address, setAddress] = useState({
    name: "",
    email: "",
    address_line_1: "",
    address_line_2: "",
    mobile: "",
    pincode: "",
    city: "",
    locality: "",
    state: "",
    signup: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [isOrderCreated, setIsOrderCreated] = useState(false);
  const dispatch = useDispatch();
  const [selectedId, setSelectedId] = useState(shippingAddressId);
  const [openCODModal, setOpenCODModal] = useState(false);
  const [confirmOrderDetails, setConfirmOrderDetails] = useState({});
  const [isCODOrderCreated, setIsCODOrderCreated] = useState(false);
  const [isOrderCancelled, setIsOrderCancelled] = useState(false);
  const [isPaymentVerified, setIsPaymentVerified] = useState(false);
  const [isPincodeLoading, setIsPincodeLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddress((prev) => ({ ...prev, [name]: value }));
  };

  const addAddress = async (e) => {
    e.preventDefault();
    if (!address?.name?.trim()) {
      toast.error("Name field is required.", {
        position: "bottom-center",
        autoClose: 2000,
        theme: "colored",
      });
      return;
    }

    if (!/^[a-zA-Z][a-zA-Z\s\-']*$/.test(address?.name)) {
      toast.error(
        "Name field should start with a letter and can only contain letters (both uppercase and lowercase), spaces, hyphens, or apostrophes.",
        {
          position: "bottom-center",
          autoClose: 2000,
          theme: "colored",
        }
      );
      return;
    }

    if (address?.name.length < 3) {
      toast.error("Name should be at least 2 characters long.", {
        position: "bottom-center",
        autoClose: 2000,
        theme: "colored",
      });
      return;
    }

    if (/\-{2,}/.test(address?.name)) {
      toast.error("Name field cannot contain consecutive hyphens.", {
        position: "bottom-center",
        autoClose: 2000,
        theme: "colored",
      });
      return;
    }

    if (!address?.email.trim()) {
      toast.error("Email field is required!.", {
        position: "bottom-center",
        autoClose: 2000,
        theme: "colored",
      });
      return;
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(address?.email)) {
      toast.error("Invalid email address.", {
        position: "bottom-center",
        autoClose: 2000,
        theme: "colored",
      });
      return;
    }

    if (!address?.mobile?.trim()) {
      toast.error("Mobile number field is required.", {
        position: "bottom-center",
        autoClose: 2000,
        theme: "colored",
      });
      return;
    }

    if (!/^\d+$/.test(address?.mobile)) {
      toast.error("Mobile number should contain only digits.", {
        position: "bottom-center",
        autoClose: 2000,
        theme: "colored",
      });
      return;
    }

    if (address?.mobile.length !== 10) {
      toast.error("Mobile number should be 10 digits long.", {
        position: "bottom-center",
        autoClose: 2000,
        theme: "colored",
      });
      return;
    }

    if (!/^[6-9]/.test(address?.mobile)) {
      toast.error("Mobile must start with 6,7,8 or 9.", {
        position: "bottom-center",
        autoClose: 2000,
        theme: "colored",
      });
      return;
    }

    if (!address?.address_line_1.trim()) {
      toast.error("H.No / Flat No. / Floor No. / Building Name field is required.", {
        position: "bottom-center",
        autoClose: 2000,
        theme: "colored",
      });
      return;
    }

    if (address?.address_line_1.length > 255) {
      toast.error("H.No / Flat No. / Floor No. / Building Name field cannot exceed 255 characters.", {
        position: "bottom-center",
        autoClose: 2000,
        theme: "colored",
      });
      return;
    }

    const regex = /^[a-zA-Z0-9\s\-\,\#\.\\/]+$/;

    if (!regex.test(address?.address_line_1)) {
      toast.error("H.No / Flat No. / Floor No. / Building Name field contains invalid characters.", {
        position: "bottom-center",
        autoClose: 2000,
        theme: "colored",
      });
      return;
    }

    if (!address?.address_line_2.trim()) {
      toast.error("Street Address field is required.", {
        position: "bottom-center",
        autoClose: 2000,
        theme: "colored",
      });
      return;
    }

    if (address?.address_line_2.length > 255) {
      toast.error("Street Address field cannot exceed 255 characters.", {
        position: "bottom-center",
        autoClose: 2000,
        theme: "colored",
      });
      return;
    }

    if (!regex.test(address?.address_line_2)) {
      toast.error("Street Address field contains invalid characters.", {
        position: "bottom-center",
        autoClose: 2000,
        theme: "colored",
      });
      return;
    }

    if (!address?.pincode.trim()) {
      toast.error("Pincode field is required.", {
        position: "bottom-center",
        autoClose: 2000,
        theme: "colored",
      });
      return;
    }

    if (!address?.city?.trim()) {
      toast.error("City field is required.", {
        position: "bottom-center",
        autoClose: 2000,
        theme: "colored",
      });
      return;
    }

    const regexExpression = /^[a-zA-Z\s]+$/;

    if (!regexExpression.test(address?.city)) {
      toast.error(
        "City field can only contain letters(both uppercase and lowercase), spaces, hyphens or apostrophes",
        {
          position: "bottom-center",
          autoClose: 2000,
          theme: "colored",
        }
      );
      return;
    }

    if (address?.city.length < 3) {
      toast.error("City should be at least 2 characters long.", {
        position: "bottom-center",
        autoClose: 2000,
        theme: "colored",
      });
      return;
    }

    if (!address?.locality?.trim()) {
      toast.error("Locality field is required.", {
        position: "bottom-center",
        autoClose: 2000,
        theme: "colored",
      });
      return;
    }

    if (!regexExpression.test(address?.locality)) {
      toast.error(
        "Locality field can only contain letters(both uppercase and lowercase), spaces, hyphens or apostrophes",
        {
          position: "bottom-center",
          autoClose: 2000,
          theme: "colored",
        }
      );
      return;
    }

    if (address?.locality.length < 3) {
      toast.error("Locality should be at least 2 characters long.", {
        position: "bottom-center",
        autoClose: 2000,
        theme: "colored",
      });
      return;
    }

    if (!address?.state?.trim()) {
      toast.error("State field is required.", {
        position: "bottom-center",
        autoClose: 2000,
        theme: "colored",
      });
      return;
    }

    if (!address?.state || !regexExpression.test(address?.state)) {
      toast.error(
        "State field can only contain letters (both uppercase and lowercase), spaces, or apostrophes",
        {
          position: "bottom-center",
          autoClose: 2000,
          theme: "colored",
        }
      );
      return;
    }

    if (address?.state.length < 3) {
      toast.error("State should be at least 2 characters long.", {
        position: "bottom-center",
        autoClose: 2000,
        theme: "colored",
      });
      return;
    }

    setIsLoading(true);

    try {
      const headers = { Authorization: accessToken };

      const response = await axios.post("v2/address/add", address, { headers });
      if (response?.data?.status === true) {
        getAddressList();
        toast.success(response?.data?.data?.message, {
          position: "bottom-center",
          autoClose: 2000,
          theme: "colored",
        });
        setOpenAddressModal(false);
        setAddress({
          name: "",
          email: "",
          address_line_1: "",
          address_line_2: "",
          mobile: "",
          pincode: "",
          city: "",
          locality: "",
          state: "",
        });
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: "add_shipping_info",
          ecommerce: {
            currency: "INR",
            value: cartItems?.grand_total,
            shipping_tier: "Ground",
            items: cartItems?.map((item, index) => ({
              item_id: item?.id,
              item_name: item?.product_name,
              affiliation: "UP LIFE",
              discount: cartItems?.discount_amount,
              index: index,
              item_brand: "UP LIFE",
              item_variant: item?.variant_title,
              price: item?.offer_price,
              quantity: item?.quantity,
            })),
          }
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const form_error = error?.response?.data?.data?.form_error;
        const parser = new DOMParser();
        const doc = parser.parseFromString(form_error, "text/html");

        const errorList = Array.from(doc.querySelectorAll("li")).map(
          (li) => li.textContent
        );

        const errorMessage = (
          <ul>
            {errorList.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        );

        toast.error(errorMessage, {
          position: "bottom-center",
          autoClose: 2000,
          theme: "colored",
        });
      }
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "bottom-center",
          autoClose: 2000,
          theme: "colored",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectedOption = (option) => {
    setSelectedOption(option);
  };

  const getCartProducts = async () => {
    try {
      const data = await axios.get(`cart/list?cart=${cartIdFromLocalStorage}`);
      const productsData = data?.data?.data?.cart;
      dispatch(setCartItems(productsData.item_list));
      const shippingId = data?.data?.data?.cart?.shipping_address_id;
      setSelectedId(shippingId);
      setCartProducts(productsData);
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "bottom-center",
          autoClose: 2000,
          theme: "colored",
        });
      }
    }
  };

  const getAddressList = async () => {
    setIsLoading(true);
    try {
      let accessToken = localStorage.getItem("access_token");
      const headers = {
        Authorization: accessToken,
      };
      const response = await axios.get("v2/address/get", { headers });
      if (response?.data?.status === true) {
        const list = response?.data?.data?.list;
        const firstAddressId = list[0]?.id;
        dispatch(setShippingAddressId(firstAddressId));
        dispatch(setAddresses(list));
        setAddressList(response?.data?.data?.list);
        setSelectedId(response?.data?.data?.list[0]?.id);
        setIsLoading(false);
        toast.success(response?.data?.data?.message, {
          position: "bottom-center",
          autoClose: 2000,
          theme: "colored",
        });
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "bottom-center",
          autoClose: 2000,
          theme: "colored",
        });
      }
    }
  };

  const selectShippingAddress = (id) => {
    setSelectedId(id);
    dispatch(setShippingAddressId(id));
  };

  const getPostalCodeDetails = async () => {
    if (!address?.pincode.trim()) {
      toast.error("Pincode is required.", {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
      });
      return;
    }

    if (!/^\d+$/.test(address?.pincode)) {
      toast.error("Pincode should contain only digits.", {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
      });
      return;
    }

    if (address?.pincode.length !== 6) {
      toast.error("Pincode should be 6 digits long.", {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
      });
      return;
    }

    setIsPincodeLoading(true);

    try {
      const response = await axios.get(
        `https://api.postalpincode.in/pincode/${address.pincode}`
      );

      if (response?.data[0]?.Status === "Success") {
        const postOffice = response?.data[0]?.PostOffice[0];
        setAddress((prevData) => ({
          ...prevData,
          locality: postOffice?.Name || "",
          city: postOffice?.Division || "",
          state: postOffice?.State || "",
        }));
      } else if (response?.data[0]?.Status === "Error") {
        toast.error(`${response?.data[0]?.Message} or Invalid Pincode.`, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        setAddress((prevData) => ({
          ...prevData,
          locality: "",
          city: "",
          state: "",
        }));
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
      });
    } finally {
      setIsPincodeLoading(false);
    }
  };

  const allocateShippingAddressToCart = async (addressId) => {
    try {
      const headers = { Authorization: accessToken };

      const data = {
        cart: cartIdFromLocalStorage,
        address: addressId,
      };

      const response = await axios.post("cart/shipping", data, { headers });
      if (response?.data?.status === true) {
        dispatch(setShippingAddressId(selectedId));
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const form_error = error?.response?.data?.data?.form_error;
        const parser = new DOMParser();
        const doc = parser.parseFromString(form_error, "text/html");

        const errorList = Array.from(doc.querySelectorAll("li")).map(
          (li) => li.textContent
        );

        const errorMessage = (
          <ul>
            {errorList.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        );

        toast.error(errorMessage, {
          position: "bottom-center",
          autoClose: 2000,
          theme: "colored",
        });
      }
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "bottom-center",
          autoClose: 2000,
          theme: "colored",
        });
      }
    }
  };

  const handlePayment = async (orderData, orderId) => {
    const orderDetails = {
      amount: orderData?.data?.configuration?.amount,
      currency: orderData?.data?.configuration?.display_currency,
      receipt: orderData?.data?.configuration?.receipt_id,
      description: orderData?.data?.configuration?.description,
      order_id: orderId,
    };

    const options = {
      key: process.env.RPAY_KEY,
      amount: orderDetails.amount,
      currency: orderDetails.currency,
      description: orderDetails.description,
      order_id: orderId,
      handler: function (response) {
        handlePaymentVerification(response);
      },
      prefill: {
        name: orderData?.data?.configuration?.prefill?.name,
        email: orderData?.data?.configuration?.prefill?.email,
        contact: orderData?.data?.configuration?.prefill?.contact,
      },
      notes: {
        address: "123, Street Name, City, Country",
      },
      theme: {
        color: orderData?.data?.configuration?.theme?.color,
      },
      modal: {
        ondismiss: function (response) {
          handlePaymentFailure(orderId);
        },
      },
    };

    localStorage.removeItem("cart_id");

    const razorpay = new window.Razorpay(options);
    razorpay.open();

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "add_payment_info",
      ecommerce: {
        currency: "INR",
        value: cartItems?.grand_total,
        payment_type: "Credit Card",
        items: cartItems?.map((item, index) => ({
          item_id: item?.id,
          item_name: item?.product_name,
          affiliation: "UP LIFE",
          discount: cartItems?.discount_amount,
          index: index,
          item_brand: "UP LIFE",
          item_variant: item?.variant_title,
          price: item?.offer_price,
          quantity: item?.quantity,
        })),
      }
    });
  };

  const placeONLINEOrder = async (mode) => {
    if (!selectedId) {
      toast.error("Please select the address.", {
        position: "bottom-center",
        autoClose: 2000,
        theme: "colored",
      });
      setIsOrderCreated(false);
      return;
    }

    setIsOrderCreated(true);

    try {
      const headers = { Authorization: accessToken };

      const data = {
        cart: cartIdFromLocalStorage,
        payment_mode: mode,
      };

      const response = await axios.post("v2/orders/create", data, { headers });
      if (response?.data?.status === true) {
        const order_id = response?.data?.data?.configuration?.order_id;
        const orderId = response?.data?.data?.order?.id;
        setOrderId(orderId);
        dispatch(setOrderedId(orderId));
        handlePayment(response?.data, order_id);
        setConfirmOrderDetails(response?.data?.data);
        dispatch(setOrderDetails(response?.data?.data?.order));

        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: "begin_checkout",
          ecommerce: {
            currency: "INR",
            value: cartItems?.grand_total,
            items: cartItems?.map((item, index) => ({
              item_id: item?.id,
              item_name: item?.product_name,
              affiliation: "UP LIFE",
              discount: cartItems?.discount_amount,
              index: index,
              item_brand: "UP LIFE",
              item_variant: item?.variant_title,
              price: item?.offer_price,
              quantity: item?.quantity,
            })),
          }
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const form_error = error?.response?.data?.data?.form_error;
        const parser = new DOMParser();
        const doc = parser.parseFromString(form_error, "text/html");

        const errorList = Array.from(doc.querySelectorAll("li")).map(
          (li) => li.textContent
        );

        const errorMessage = (
          <ul>
            {errorList.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        );

        toast.error(errorMessage, {
          position: "bottom-center",
          autoClose: 2000,
          theme: "colored",
        });
      }
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "bottom-center",
          autoClose: 2000,
          theme: "colored",
        });
      }
    } finally {
      setIsOrderCreated(false);
    }
  };

  const handlePaymentFailure = async (id) => {
    setIsOrderCancelled(true);
    try {
      let accessToken = localStorage.getItem("access_token");
      const headers = {
        Authorization: accessToken,
      };
      const formdata = new FormData();

      formdata.append("rpay_order_id", id);

      const response = await axios.post("v2/orders/payment/failure", formdata, {
        headers,
      });
      if (response?.data?.status === true) {
        toast.error(response?.data?.data?.message, {
          position: "bottom-center",
          autoClose: 3000,
          theme: "colored",
        });
        dispatch(clearCart());
        localStorage.removeItem("cart_id");
        navigate("/payment-failure");
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "bottom-center",
          autoClose: 2000,
          theme: "colored",
        });
      }
    } finally {
      setIsOrderCancelled(true);
    }
  };

  const calculateOffer = () => {
    const result = offer_price * 0.05;
    return result.toFixed(2);
  };

  const handlePaymentVerification = async (razorpay) => {
    setIsPaymentVerified(true);
    try {
      let accessToken = localStorage.getItem("access_token");
      const headers = {
        Authorization: accessToken,
      };

      const formdata = new FormData();

      formdata.append("rpay_order_id", razorpay?.razorpay_order_id);
      formdata.append("razorpay_payment_id", razorpay?.razorpay_payment_id);
      formdata.append("razorpay_signature", razorpay?.razorpay_signature);

      const response = await axios.post("v2/orders/payment/verify", formdata, {
        headers,
      });
      if (response?.data?.status === true) {
        dispatch(setOrderDetails(response?.data?.data?.order));
        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
          event: "purchase",
          ecommerce: {
            currency: "INR",
            value: cartItems?.grand_total,
            items: cartItems?.map((item, index) => ({
              item_id: item?.id,
              item_name: item?.product_name,
              affiliation: "UP LIFE",
              discount: cartItems?.discount_amount,
              index: index,
              item_brand: "UP LIFE",
              item_variant: item?.variant_title,
              price: item?.offer_price,
              quantity: item?.quantity,
            })),
          }
        });
        dispatch(clearCart());
        navigate("/order-success");

      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "bottom-center",
          autoClose: 2000,
          theme: "colored",
        });
      }
    } finally {
      setIsPaymentVerified(false);
    }
  };

  const placeOrderCOD = async () => {
    setIsCODOrderCreated(true);
    try {
      const headers = { Authorization: accessToken };
      const data = {
        cart: cartIdFromLocalStorage,
        payment_mode: "COD",
      };

      const response = await axios.post("v2/orders/create", data, { headers });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "bottom-center",
          autoClose: 2000,
          theme: "colored",
        });

        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: "begin_checkout",
          ecommerce: {
            currency: "INR",
            value: cartItems?.grand_total,
            items: cartItems?.map((item, index) => ({
              item_id: item?.id,
              item_name: item?.product_name,
              affiliation: "UP LIFE",
              discount: cartItems?.discount_amount,
              index: index,
              item_brand: "UP LIFE",
              item_variant: item?.variant_title,
              price: item?.offer_price,
              quantity: item?.quantity,
            })),
          }
        });

        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
          event: "purchase",
          ecommerce: {
            currency: "INR",
            value: cartItems?.grand_total,
            items: cartItems?.map((item, index) => ({
              item_id: item?.id,
              item_name: item?.product_name,
              affiliation: "UP LIFE",
              discount: cartItems?.discount_amount,
              index: index,
              item_brand: "UP LIFE",
              item_variant: item?.variant_title,
              price: item?.offer_price,
              quantity: item?.quantity,
            })),
          }
        });
        dispatch(clearCart());
        localStorage.removeItem("cart_id");
        dispatch(setOrderDetails(response?.data?.data?.order));
        setConfirmOrderDetails(response?.data?.data);
        navigate("/order-success");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const form_error = error?.response?.data?.data?.form_error;
        const parser = new DOMParser();
        const doc = parser.parseFromString(form_error, "text/html");

        const errorList = Array.from(doc.querySelectorAll("li")).map(
          (li) => li.textContent
        );

        const errorMessage = (
          <ul>
            {errorList.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        );

        toast.error(errorMessage, {
          position: "bottom-center",
          autoClose: 2000,
          theme: "colored",
        });
      }
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "bottom-center",
          autoClose: 2000,
          theme: "colored",
        });
      }
    } finally {
      setIsCODOrderCreated(false);
    }
  };

  const handleNavigate = () => {
    navigate("/sleepzzz-pack-of-1");
  };

  useEffect(() => {
    getCartProducts();
    getAddressList();
    setSelectedId(shippingAddressId);
    allocateShippingAddressToCart(shippingAddressId);
  }, []);

  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js")
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error loading Razorpay script:", error);
      });
  }, []);

  if (isPaymentVerified) {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            background: "#fff",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            maxWidth: "300px",
          }}
        >
          <Puff
            height="80"
            width="80"
            color="#7B1FA2"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
          <p style={{ marginTop: "20px", textAlign: "center" }}>Loading...</p>
        </div>
      </div>
    );
  }

  if (isOrderCancelled) {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            background: "#fff",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            maxWidth: "300px",
          }}
        >
          <Puff
            height="80"
            width="80"
            color="#7B1FA2"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
          <p style={{ marginTop: "20px", textAlign: "center" }}>Loading...</p>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            background: "#fff",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            maxWidth: "300px",
          }}
        >
          <Puff
            height="80"
            width="80"
            color="#7B1FA2"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
          <p style={{ marginTop: "20px", textAlign: "center" }}>Loading...</p>
        </div>
      </div>
    );
  }

  if (isCODOrderCreated) {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            background: "#fff",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            maxWidth: "300px",
          }}
        >
          <Puff
            height="80"
            width="80"
            color="#7B1FA2"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
          <p style={{ marginTop: "20px", textAlign: "center" }}>Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <main className="payment banner-section py-3 py-lg-5">
        <div className="container banner-container">
          <div className="row bg-white pt-5 border-radius d-lg-flex p-2">
            {totalCartQty === 0 ? (
              <span className="text-center text-danger">
                Your cart has 0 items, Please add some gummies!.
              </span>
            ) : (
              ""
            )}
            <div className="col-lg-8 pb-2 border-1 border-right mb-md-4 pr-0 pr-md-3">
              <div className="d-flex align-items-center mb-2">
                <FaArrowLeft
                  size={22}
                  onClick={handleNavigate}
                  style={{ cursor: "pointer" }}
                />
                <h5 className="mx-3 select-address-font">
                  Select Delivery Address
                </h5>
              </div>
              <div className="border-3 mt-2">
                <hr />

                <HorizontalScroll
                  list={addressList}
                  selectShippingAddress={selectShippingAddress}
                  selectedId={selectedId}
                  allocateShippingAddressToCart={allocateShippingAddressToCart}
                />
                <div className="mx-3 mt-5 d-flex align-items-center fw-bold">
                  <span className="mx-1" style={{ marginTop: "-5px" }}>
                    <FaPlus size={14} />
                  </span>
                  <h6
                    className="cursor-pointer fw-bold"
                    onClick={() => setOpenAddressModal(true)}
                  >
                    Add New Address
                  </h6>

                </div>
                <hr />
                <div>
                  <h5 className="mx-3 m-3" style={{ fontSize: "18px" }}>
                    Select Payment Method
                  </h5>
                  <div className="row d-flex justify-content-around align-items-center text-center">
                    <div
                      className={`col-lg-5 col-md-5 col-11 payment-on-delivery ${selectedOption === "COD" ? "selected" : ""
                        }`}
                      onClick={() => handleSelectedOption("COD")}
                    >
                      <h6 className="text-black fw-bold">Pay On Delivery</h6>
                      <small className="text-black font-size small-font">
                        Our delivery partner accepts Cash on Delivery as well as
                        UPI/Cards
                      </small>
                    </div>
                    <div
                      style={{ position: "relative" }}
                      className={`col-lg-5 col-md-5 col-11 mt-4 mt-md-0 mt-lg-0  payment-on-delivery ${selectedOption === "ONLINE" ? "selected" : ""
                        }`}
                      onClick={() => {
                        handleSelectedOption("ONLINE");
                      }}
                    >
                      <div
                        className="offer-image-div"
                      >
                        <img
                          src="/assets/offer-image.webp"
                          alt=""
                          className="offer-image"
                        />
                      </div>
                      <div>
                        <h6 className="text-black fw-bold">Online Payment</h6>
                        <div className="pt-2">
                          <img
                            className="img-fluid"
                            src="assets/logos_google-pay-icon.webp"
                            alt=""
                          />
                          <img
                            className="img-fluid"
                            src="assets/phonepe.webp"
                            alt=""
                          />
                          <img
                            className="img-fluid"
                            src="assets/upi.webp"
                            alt=""
                          />
                        </div>
                        <h6 className="fw-bold text-primary">
                          Save ₹ {calculateOffer() || 0}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* ---------------------------------------- ORDER SUMMARY  -----------------------------------------*/}

            <div className="col-lg-4 col-md-12 pl-3 order-summary">
              <h5 className="fw-500 text-center text-md-left pt-lg-0">
                Order Summary
              </h5>
              <hr />
              <div style={{ height: "255px", overflow: "auto" }}>
                {cartItems?.map((product) => (
                  <Card className="mb-3">
                    <CardBody>
                      <div className="d-flex align-items-center gap-2">
                        <div style={{ width: "120px" }}>
                          <img
                            src={product?.product_image}
                            alt="product-pic"
                            style={{ width: "100%" }}
                          />
                        </div>
                        <div>
                          <p style={{ fontSize: "14px" }} className="fw-bold">
                            {product?.product_name}
                          </p>
                          <p className="small-font-size">
                            {product?.content_qty} Gummies.
                          </p>
                          <p className="small-font-size text-warning">
                            <i>{product?.variant_title}</i>
                          </p>
                          <p className="small-font-size fw-semibold">
                            Qty: <span>{product?.quantity}</span>
                          </p>
                          <div className="bottom-price" style={{ position: "relative", marginLeft: '0px' }}>
                            <p
                              className="fw-semibold cart-mrp-price"
                              style={{ position: "relative" }}
                            >
                              <span
                                style={{ position: "relative", color: "#8c8c8d" }}
                              >
                                ₹ {product?.mrp * product?.quantity}
                              </span>
                              <span className="mx-2 fw-bold">
                                ₹ {product?.offer_price * product?.quantity}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                ))}
              </div>
              <hr />
              <div className="row">
                <div className="col-7 py-2">
                  <h6 className="text-left text-muted">Total MRP</h6>
                </div>
                <div className="col-5 text-right px-lg-5 py-2">
                  <h6 className="fw-500 text-muted">₹ {cartMrp}</h6>
                </div>
                <div className="col-7 py-2">
                  <h6 className="text-success text-left">Discount on MRP</h6>
                </div>
                <div className="col-5 text-right px-lg-5 py-2">
                  <h6 className="text-success">₹ - {cartMrp - offer_price}</h6>
                </div>
                {selectedOption === "ONLINE" ? (
                  <>
                    <div className="col-7 py-2 text-primary">
                      <h6 className="text-primary text-left ">
                        Extra Discount
                      </h6>
                    </div>
                    <div className="col-5 text-right px-lg-5 py-2">
                      <h6 className="text-primary">
                        ₹ {calculateOffer() || 0}
                      </h6>
                    </div>
                  </>
                ) : null}
                <div className="col-7 py-2">
                  <h6 className="text-left  text-muted">Delivery</h6>
                </div>
                <div className="col-5 text-right px-lg-5 py-2">
                  <h6 className="text-muted">FREE</h6>
                </div>
              </div>
              <div className="row border-top pt-3">
                <div className="col-6">
                  <h6
                    className="text-primary text-left"
                    style={{ fontSize: "16px" }}
                  >
                    Grand Total
                  </h6>
                </div>
                <div className="col-6 text-right px-lg-5">
                  <h6 className="text-primary" style={{ fontSize: "16px" }}>
                    ₹{" "}
                    {selectedOption === "ONLINE"
                      ? offer_price - calculateOffer()
                      : offer_price}
                  </h6>
                </div>
              </div>
              <div className="d-flex justify-content-center pt-1 pb-3">
                {selectedOption === "ONLINE" ? (
                  <button
                    disabled={totalCartQty === 0 || selectedOption === ""}
                    className="btn w-100 buy-btn mt-3 white-text"
                    onClick={() => placeONLINEOrder("ONLINE")}
                  >
                    PROCEED TO PAY
                  </button>
                ) : (
                  <button
                    disabled={selectedOption === "" || totalCartQty === 0}
                    className="btn w-100 buy-btn mt-3"
                    onClick={placeOrderCOD}
                  >
                    ORDER NOW
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>

      <Modal
        show={openAddressModal}
        onHide={() => setOpenAddressModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeader closeButton>
          <ModalTitle>ADD ADDRESS</ModalTitle>
        </ModalHeader>
        <Modal.Body className="modal-body-background">
          <form onSubmit={addAddress}>
            <Row className="mb-3">
              <Col className="col-12 col-md-6 col-lg-4 mb-3">
                <div className="form-group rounded">
                  <label for="phone">Name</label>
                  <input
                    name="name"
                    value={address.name}
                    type="text"
                    className="form-control rounded-right border-left-0"
                    id="phone"
                    placeholder="Enter Name"
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col className="col-12 col-md-6 col-lg-4 mb-3">
                <div className=" form-group rounded">
                  <label for="phone">Email</label>
                  <input
                    name="email"
                    value={address.email}
                    type="text"
                    className="form-control rounded-right border-left-0"
                    id="phone"
                    placeholder="Enter Email"
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col className="col-12 col-md-6 col-lg-4 mb-3">
                <div className=" form-group rounded">
                  <label for="phone">Mobile Number</label>
                  <input
                    name="mobile"
                    value={address.mobile}
                    type="phone"
                    className="form-control  rounded-right border-left-0"
                    id="phone"
                    placeholder="Mobile Number"
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col className="col-12 col-md-6 col-lg-6 mb-3">
                <div className=" form-group rounded">
                  <label for="address-1">H.No / Flat No. / Floor No. / Building Name</label>
                  <input
                    name="address_line_1"
                    value={address.address_line_1}
                    type="text"
                    className="form-control  rounded-right border-left-0"
                    id="address-1"
                    placeholder="H.No / Flat No. / Floor No."
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col className="col-12 col-md-6 col-lg-6 mb-3">
                <div className=" form-group rounded">
                  <label for="address-2">Street address</label>
                  <input
                    name="address_line_2"
                    value={address.address_line_2}
                    type="text"
                    className="form-control  rounded-right border-left-0"
                    id="address-2"
                    placeholder="Street address"
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col className="col-12 col-md-6 col-lg-3 mb-3">
                <div
                  className=" pr-lg-0 form-group rounded"
                  style={{ position: "relative" }}
                >
                  <label for="pincode">Pincode</label>
                  <input
                    name="pincode"
                    value={address.pincode}
                    type="text"
                    className="form-control  rounded-right border-left-0"
                    id="pincode"
                    placeholder="Enter Pincode"
                    onChange={handleChange}
                    onBlur={getPostalCodeDetails}
                  />
                  {isPincodeLoading && (
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-0%)",
                      }}
                    >
                      <RotatingLines
                        height="20"
                        width="20"
                        color="#7B1FA2"
                        ariaLabel="circles-loading"
                        visible={true}
                      />
                    </div>
                  )}
                </div>
              </Col>
              <Col className="col-12 col-md-6 col-lg-3">
                <div className="form-group rounded">
                  <label for="city">City</label>
                  <input
                    name="city"
                    value={address.city}
                    type="text"
                    className="form-control  rounded-right border-left-0"
                    id="city"
                    placeholder="Enter City"
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col className="col-12 col-md-6 col-lg-3 mb-3">
                <div className="form-group rounded">
                  <label for="locality">Locality</label>
                  <input
                    name="locality"
                    value={address.locality}
                    type="text"
                    className="form-control  rounded-right border-left-0"
                    id="locality"
                    placeholder="Enter Locality"
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col className="col-12 col-md-6 col-lg-3 mb-3">
                <div className="form-group rounded">
                  <label for="state">State</label>
                  <input
                    name="state"
                    value={address.state}
                    type="text"
                    className="form-control  rounded-right border-left-0"
                    id="state"
                    placeholder="Enter State"
                    onChange={handleChange}
                  />
                </div>
              </Col>
            </Row>
            <div className="text-center  mb-3">
              <PrimaryButton
                title="ADD ADDRESS"
                style={{ width: "50%" }}
                isLoading={isLoading}
              />
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <div>
        {isOrderCreated ? (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                background: "#fff",
                padding: "20px",
                borderRadius: "8px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                maxWidth: "300px",
              }}
            >
              <Puff
                height="80"
                width="80"
                color="#7B1FA2"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
              <p style={{ marginTop: "20px", textAlign: "center" }}>
                Loading...
              </p>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default SelectAddress;
