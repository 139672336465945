import React from 'react'

const PrivacyPolicy = () => {
    return (
        <main className="address-form banner-section">
            <div className="container banner-container">
                <div className="row">
                    <div className="col-md-11 mx-auto">
                        <div className="card">
                            <div className="card-body">
                                <div className="text-center mb-5">
                                    <h2>PRIVACY POLICY</h2>
                                </div>
                                <h5 className="sub-title" style={{ letterSpacing: '8px' }}>
                                    <i>
                                        <b>LAST MODIFIED:</b>
                                        22 AUGUST 2023
                                    </i>
                                </h5>
                                <p className="sub-title mt-5 mb-2">
                                    <u className="medium-font-size"> <i>COLLECTION OF INFORMATION:</i> </u>
                                </p>
                                <p>
                                    When you make a purchase on our website, we collect personal information such as your name, shipping address, mobile no. and email-ID. This information is necessary to process your order and fulfil our contractual obligations to you.

                                    We may also collect information about you from third-party sources, such as social media platforms, if you choose to interact with us on these platforms.
                                </p>
                                <p className="sub-title mt-4 mb-2">
                                    <u className="medium-font-size"><i>USE OF INFORMATION:</i></u>
                                </p>
                                <p className="height-line">
                                    We use the information we collect to process your orders, improve our website and services, and communicate with you about our products and promotions. We may also use your information for market research and to analyse our business performance.

                                    We do not sell or rent your personal information to third parties. However, we may share your information with trusted third-party service providers who assist us in operating our website, processing payments, and delivering products to you.

                                    We may also disclose your information to law enforcement agencies or government authorities if required by law or if we believe it is necessary to protect our legal rights or the safety of our customers.
                                </p>
                                <p className="sub-title mt-4 mb-2">
                                    <u className="medium-font-size"><i>PROTECTION OF INFORMATION:</i></u>
                                </p>
                                <p className="height-line">
                                    We take reasonable measures to protect your personal information from unauthorised access, use, or disclosure. We use third party payment gateways, and hence don’t store any payment details.

                                    We also limit access to your personal information to authorised personnel only and require them to keep your information confidential.
                                </p>
                                <p className="sub-title mt-4 mb-2">
                                    <u className="medium-font-size"><i>RETENTION OF INFORMATION:</i></u>
                                </p>
                                <p className="height-line">
                                    We retain your personal information for as long as necessary to fulfil our contractual obligations to you and to comply with legal and regulatory requirements. We may also retain your information for research and analysis purposes in order to improve our services.
                                </p>

                                <p className="sub-title mt-4 mb-2">
                                    <u className="medium-font-size"><i>CHANGES TO POLICY:</i></u>
                                </p>
                                <p className="height-line">
                                    We reserve the right to modify this privacy policy at any time. We will post any changes on our website and update the "last updated" date at the top of this page.
                                </p>

                                <p className="sub-title mt-4 mb-2">
                                    <u className="medium-font-size"><i>CONTACT US</i></u>
                                </p>
                                <p className="height-line">
                                    If you have any questions or concerns about our terms and
                                    conditions, please contact us at sales@theuplife.in or by
                                    phone at +91 98176 56667.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default PrivacyPolicy
