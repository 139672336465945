import React from "react";
import "aos/dist/aos.css";
import './BenefitsSection.css'

const BenefitsSection = () => {
    return (
        <section className="benifits-section" id="benefits">
            <div className="container">
                <div>
                    <div className="row">
                        <div className="col-12">
                            <h1 className="text-center benefit-heading">Sleepzzz Gummies that Helps You</h1>
                        </div>
                        <div className="col-6 col-md-4 order-md-1 pl-md-5 benefit-points">
                            <ul className="benifits-list" data-aos="fade-right"
                                data-aos-offset="300"
                                data-aos-easing="ease-in-sine">
                                <li data-aos="fade-right" data-aos-delay="0">
                                    Stronger Immunity
                                </li>
                                <li data-aos="fade-right" data-aos-delay="200">
                                    Mood Booster
                                </li>
                                <li data-aos="fade-right" data-aos-delay="400">
                                    Natural relaxant
                                </li>
                                <li data-aos="fade-right" data-aos-delay="600">
                                    Reduces Sleeplessness
                                </li>
                                <li
                                    data-aos="fade-right"
                                    data-aos-delay="800"
                                    className="d-md-none"
                                >
                                    Rich in Antioxidants
                                </li>
                                <li
                                    data-aos="fade-right"
                                    data-aos-delay="1000"
                                    className="d-md-none"
                                >
                                    Energy Booster
                                </li>
                            </ul>
                        </div>
                        <div className="col-6 col-md-4 order-md-3 pl-md-5 benefit-points">
                            <ul className="benifits-list" data-aos="fade-right"
                                data-aos-offset="300"
                                data-aos-easing="ease-in-sine">
                                <li
                                    data-aos="fade-right"
                                    data-aos-delay="200"
                                    className="d-md-none"
                                >
                                    Improves Male Fertility
                                </li>
                                <li
                                    data-aos="fade-right"
                                    data-aos-delay="400"
                                    className="d-md-none"
                                >
                                    Keeps Heart Healthy
                                </li>
                                <li data-aos="fade-right" data-aos-delay="600">
                                    Boost productivity
                                </li>
                                <li data-aos="fade-right" data-aos-delay="800">
                                    Mental focus
                                </li>
                                <li data-aos="fade-right" data-aos-delay="1000">
                                    Alleviates Menopausal symptoms
                                </li>
                            </ul>
                        </div>
                        <div className="col-6 col-md-4 d-none d-md-block order-md-2 pl-md-5">
                            <ul className="benifits-list" data-aos="fade-right"
                                data-aos-offset="300"
                                data-aos-easing="ease-in-sine">
                                <li data-aos="fade-right" data-aos-delay="200">
                                    Rich in Antioxidants
                                </li>
                                <li data-aos="fade-right" data-aos-delay="400">
                                    Energy Booster
                                </li>
                                <li data-aos="fade-right" data-aos-delay="600">
                                Improves Male Fertility
                                </li>
                                <li data-aos="fade-right" data-aos-delay="800">
                                Keeps Heart Healthy
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sleeping-img">
                <img src="assets/sleeping-lady.webp" className="img-fluid" alt="sleeping-lady" width="100%" />
            </div>
        </section>
    );
};

export default BenefitsSection;
