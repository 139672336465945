import React, { useEffect, useState } from 'react'
import axios from '../../api/axios';
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from "react-icons/fa6";
import { Button } from 'react-bootstrap';

const BlogDetails = () => {
    const [singleBlogData, setSingleBlogData] = useState([]);
    const [blogsData, setBlogsData] = useState([]);
    const navigate = useNavigate()

    const { blogUriSlug } = useParams();

    const getSingleBlogData = async () => {
        try {
            const response = await axios(`blogs/single?slug=${blogUriSlug}`);
            if (response?.data?.status === true) {
                const data = response?.data?.data?.blog;
                setSingleBlogData(data);
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const getBlogsData = async () => {
        try {
            const response = await axios(`blogs/get`);

            if (response?.data?.status === true) {
                const data = response?.data?.data?.list;
                setBlogsData(data);
            }

        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };
    const navigateToBlogs = () => {
        navigate('/blogs')
    }

    useEffect(() => {
        getSingleBlogData();
        getBlogsData()
    }, [blogUriSlug]);

    return (
        <section className="py-4">
            <div className="container banner-container">
                <div className="card">
                    <div className="card-body blog-details">
                        <div className="row">
                            <div className="col-12 col-lg-8 position-relative">
                                <Button className='start-10 left-arrow-btn'>
                                    <FaArrowLeft size={25} onClick={navigateToBlogs} />
                                </Button>
                                <img className='img-fluid mb-4 d-block mx-auto' src={singleBlogData?.blog_image_path} alt="Sleeping Gummies: Natural Solutions and Homeopathy Remedies" />
                                <h2>{singleBlogData?.title}</h2>
                                <p>
                                    {singleBlogData?.meta_description}
                                </p>
                                <p dangerouslySetInnerHTML={{ __html: singleBlogData?.content }} className='justify-text' />
                            </div>


                            {/* <-------------------------------------RECENT BLOGS--------------------------------------------------> */}

                            <div className="col-12 col-lg-4">
                                <div className="recent-blogs">
                                    <h3>Recent Blogs</h3>
                                    {
                                        blogsData?.map((blog) => <div className="small-blog mb-4 d-flex gap-2">
                                            <a href="">
                                                <img src={blog?.blog_image_path} width='100px' height='80px' alt='blog-image' />
                                            </a>
                                            <div className="text">
                                                <h3 className="heading">
                                                    <Link to={`/blogs/${blog?.uri_slug}`}>{blog?.title}</Link>
                                                </h3>
                                                <p className="mb-0">
                                                    <a href="#">{blog?.created_at?.split(" ")[0]}</a> | <a href="#">12 min read</a>
                                                </p>
                                            </div>
                                        </div>)
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default BlogDetails
