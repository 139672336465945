import React from 'react'
import { Spinner } from 'react-bootstrap';

const PrimaryButton = ({ title, isLoading, style }) => {
    const mergedStyle = {
        backgroundImage: "url('/assets/buy-btn-background.webp')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        color: "#fff",
        textAlign: "center",
        padding: "10px",
        backgroundPosition: "center",
        borderRadius: "40px", 
        fontSize: "18px",
        width: "100%",
        border: 'none',
        ...style,
    };
    
    return (
        <button type="submit" style={mergedStyle}>
            {isLoading ? (
                <span className="d-flex align-items-center justify-content-center gap-2">
                    <Spinner size="sm" /> {" Loading..."}
                </span>
            ) : (
                <span>{title}</span>
            )}
        </button>
    )
}

export default PrimaryButton
