import React from "react";

const TermsAndConditions = () => {
  return (
    <main className="address-form banner-section">
      <div className="container banner-container">
        <div className="row">
          <div className="col-md-11 mx-auto">
            <div className="card">
              <div className="card-body">
                <div className="text-center mb-5">
                  <h2>TERMS AND CONDITIONS</h2>
                </div>
                <h5 className="sub-title" style={{ letterSpacing: '10px' }}>
                  <i>
                    <b>LAST MODIFIED:</b>
                    22 AUGUST 2023
                  </i>
                </h5>
                <p className="sub-title mt-5 mb-2">
                  <u className="medium-font-size"> <i>INTRODUCTION:</i> </u>
                </p>
                <p>
                  Please read these terms and conditions carefully before using
                  our website. By using our website, you agree to these terms
                  and conditions.
                </p>
                <p className="sub-title mt-4 mb-2">
                  <u className="medium-font-size"><i>USE OF WEBSITE:</i></u>
                </p>
                <p className="height-line">
                  You may use our website for personal and non-commercial
                  purposes only. You may not use our website for any illegal or
                  unauthorised purpose, including but not limited to:
                </p>
                <p className="height-line">
                  Posting or transmitting any content that is illegal,
                  offensive, or violates the rights of others.
                </p>
                <p className="height-line">
                  Interfering with the operation of our website, including but
                  not limited to hacking, phishing, or transmitting viruses.
                </p>
                <p className="height-line">
                  Using any automated system, software, or device to access,
                  scrape, or crawl our website without our express consent.
                </p>
                <p className="sub-title mt-4 mb-2">
                  <u className="medium-font-size"><i>INTELLECTUAL PROPERTY:</i></u>
                </p>
                <p className="height-line">
                  All content on our website, including but not limited to
                  images of our products, graphics, logos, and trademarks, is
                  the property of our website or our licensors and is protected
                  by Indian and international intellectual property laws.
                </p>
                <p className="height-line">
                  You may not copy, reproduce, distribute, transmit, or display
                  any content from our website without our express written
                  consent.
                </p>
                <p className="sub-title mt-4 mb-2">
                  <u className="medium-font-size"><i>PRODUCT INFORMATION:</i></u>
                </p>
                <p className="height-line">
                  We make every effort to ensure that the information on our
                  website is accurate and up-to-date.
                </p>
                <p className="height-line">
                  The information on our website is for informational purposes
                  only and should not be considered medical advice. You should
                  always consult a healthcare professional before using our
                  products.
                </p>
                <p className="sub-title mt-4 mb-2">
                  <u className="medium-font-size"><i>ORDERS AND PAYMENT:</i></u>
                </p>
                <p className="height-line">
                  When you place an order on our website, you agree to purchase
                  the products at the prices listed on our website. We reserve
                  the right to change our prices at any time without prior
                  notice.
                </p>
                <p className="height-line">
                  We accept payments through various payment methods, including
                  credit and debit cards, net banking, and mobile wallets. We
                  use industry-standard encryption technologies to protect your
                  payment information.
                </p>

                <p className="sub-title mt-4 mb-2">
                  <u className="medium-font-size"><i>SHIPPING AND DELIVERY:</i></u>
                </p>
                <p className="height-line">
                  We offer shipping to locations within India only. We make
                  every effort to deliver your order as soon as possible, but we
                  cannot guarantee delivery times.
                </p>

                <p className="sub-title mt-4 mb-2">
                  <u className="medium-font-size"><i>RETURN AND REFUND:</i></u>
                </p>
                <p>
                  Please refer to our{" "}
                  <a href="https://theuplife.in/refund-policy">refund policy</a>
                </p>
                <p className="sub-title mt-4 mb-2">
                  <u className="medium-font-size"><i>CHANGES TO TERMS AND CONDITIONS:</i></u>
                </p>
                <p className="height-line">
                  We reserve the right to modify these terms and conditions at
                  any time. We will post any changes on our website and update
                  the "last updated" date at the top of this page.
                </p>
                <p className="sub-title mt-4 mb-2">
                  <u className="medium-font-size"><i>CONTACT US</i></u>
                </p>
                <p className="height-line">
                  If you have any questions or concerns about our terms and
                  conditions, please contact us at sales@theuplife.in or by
                  phone at +91 98176 56667.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default TermsAndConditions;
