import React from 'react'
import Slider from 'react-slick';

const HorizontalScroll = ({ list, selectShippingAddress, selectedId, allocateShippingAddressToCart }) => {
    const handleClick = (id) => {
        selectShippingAddress(id);
        allocateShippingAddressToCart(id)
    }

    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        centerPadding: "60px",
    };


    const mobilePicsSettings = {
        dots: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
    }

    const picsDiv = {
        width: '97%',
        color: 'black',
        borderRadius: '8px',
    }

    return (
        <>
            <div className="slider-container d-md-block d-lg-block d-none">
                <Slider {...settings}>
                    {list.map((address) => (
                        <div key={address.id}>
                            <div onClick={() => handleClick(address.id)} className={`mb-3 mx-3 shipping-address-box p-3 ${selectedId === address.id ? "selected-box" : "not-selected-box"}`}>
                                <p className="fs-6 fw-semibold">{address?.full_name}</p>
                                <p className="small-font-size fw-semibold">{address?.email}</p>
                                <p className="small-font-size">{address?.street_address_line_1}, {address?.street_address_line_2}</p>
                                <p className="small-font-size">{`${address?.locality}, ${address?.city} - ${address?.pincode}`}</p>
                                <p className="small-font-size">{address?.state}</p>
                                <p className="fw-semibold">+91 - {address?.mobile}</p>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>

            <div className="slider-container d-md-none d-lg-none">
                <Slider {...mobilePicsSettings}>
                    {list.map((address) => (
                        <div key={address.id}>
                            <div onClick={() => handleClick(address.id)} className={`mb-3 mx-3 shipping-address-box p-3 ${selectedId === address.id ? "selected-box" : "not-selected-box"}`}>
                                <p className="fs-6 fw-semibold">{address?.full_name}</p>
                                <p className="small-font-size fw-semibold">{address?.email}</p>
                                <p className="small-font-size">{address?.street_address_line_1}, {address?.street_address_line_2}</p>
                                <p className="small-font-size">{`${address?.locality}, ${address?.city} - ${address?.pincode}`}</p>
                                <p className="small-font-size">{address?.state}</p>
                                <p className="fw-semibold">+91 - {address?.mobile}</p>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </>

    )
}

export default HorizontalScroll
