import React, { useState } from "react";
import './HowItWorksSection.css'
import { Card, Collapse, Image } from "react-bootstrap";
import { MdKeyboardDoubleArrowDown, MdKeyboardDoubleArrowUp } from "react-icons/md";

const HowItWorksSection = React.memo(() => {
    const [open, setOpen] = useState({
        affron: false,
        brahmi: false,
        shankapushpi: false,
        lavender: false,
        chamomile: false,
        melatonin: false,
    });

    const handleToggle = (key) => {
        setOpen((prevState) => ({ ...prevState, [key]: !prevState[key] }));
    };
    return (
        <section className="how-it-works" id="ingredients">
            <div className="container banner-container mx-auto">
                <div className="row">
                    <div className="col-12 text-center">
                        <img src="assets/sun.webp" className="img-fluid pill-image" alt="sun" loading="lazy" />
                        <h1>How it Works?</h1>
                    </div>
                    <div className="col-12 text-center mb-3 mb-lg-5">
                        <p className="text-white">
                            Sleep better, faster with Sleepzzz gummies. Created with affron
                            Which is extracted from saffron. Affron is traditionally used in
                            ancient ayarveda and is now an award winning ingredient in modern
                            day sceince, winning more than 21 awards. The gummies also include
                            Brahmi, Shankapushpi, Lavender, Chamomile, Melatonin & other
                            herbs. just one pop and your off to sleep.{" "}
                        </p>
                    </div>
                </div>
                <div className="row ingredients d-md-none">
                    <div className="col-12 ingredient-card">
                        <Card>
                            <Card.Body className="pink-card">
                                <div className="row">
                                    <div className="col-4 text-center my-auto">
                                        <Image src="assets/affron.webp" className="img-fluid" alt="affron" loading="lazy" width="100%" />
                                    </div>
                                    <div className="col-8 pl-0">
                                        <h2>Affron</h2>
                                        <ul>
                                            <li>Maintains positive mood</li>
                                            <li>Induces relaxation</li>
                                            <li>Alleviate anxiety, Stress & tension reduction</li>
                                            <li>Improves sleep quality</li>
                                        </ul>
                                    </div>
                                    <div className="col-12">
                                        <Collapse in={open.affron}>
                                            <div id="affron-text">
                                                <p>
                                                    Affron is a botanical extract from saffron. Saffron,
                                                    besides being a spice used for more than 3500 years, has
                                                    its deep roots in ancient ayurveda & has been used for
                                                    centuries, It is believed to have a range of health
                                                    benefits due to its active ingredients, which include
                                                    crocin, crocetin, and safranal. And today is being highly
                                                    researched, uncovering various benefits. Affron® is a
                                                    premium saffron ingredient standardised to 3.5%
                                                    Lepticrosalides®, by HPLC, a complex of bioactive
                                                    compounds responsible for the beneficial properties and
                                                    organoleptic properties of affron®* It is backed by 8
                                                    human clinical studies that have shown to improve mood in
                                                    healthy consumers with occasional stress and anxiety, and
                                                    to improve sleeplessness*Why affron®?USA Award-winner
                                                    Ingredient for Cognitive Health 2020.8 clinical studies
                                                    published*.Proprietary extraction process AFF®ON
                                                    Cool-Tech, which uniquely concentrates and preserves the
                                                    bioactives with lower energy use.Genuine Crocus sativus L.
                                                    Made in Spain.Lowest dosage: 28 mg/day.Unique
                                                    dose-response study in 128 healthy adults*.Clinically
                                                    studied in adolescents*.
                                                </p>
                                            </div>
                                        </Collapse>
                                    </div>
                                    <div className="col-12 text-right read-more-button">
                                        <a
                                            href="javascript:void(0)"
                                            className="read-more"
                                            onClick={() => handleToggle('affron')}
                                            aria-expanded={open.affron}
                                            aria-controls="affron-text"
                                        >
                                            {
                                                open.affron ? "Read Less" : "Read More"
                                            }

                                            {
                                                !open.affron ? <MdKeyboardDoubleArrowDown size={15} /> : <MdKeyboardDoubleArrowUp size={15} />
                                            }
                                        </a>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-12 ingredient-card">
                        <Card>
                            <Card.Body className="blue-card">
                                <div className="row">
                                    <div className="col-4 text-center my-auto">
                                        <Image src="assets/brahmi.webp" className="img-fluid" alt="brahmi" loading="lazy" width="100%" />
                                    </div>
                                    <div className="col-8">
                                        <h2>Brahmi</h2>
                                        <ul>
                                            <li>Reduction of stress through</li>
                                            <li> Retention, creation, and </li>
                                            <li>Retrieval of memory</li>
                                            <li>Sound sleep</li>
                                            <li>Reduction of anxiety</li>
                                        </ul>
                                    </div>
                                    <div className="col-12">
                                        <Collapse in={open.brahmi}>
                                            <div id="brahmi-text">
                                                <p>
                                                    Brahmi is largely treasured as a revitalising herb used by
                                                    Ayurvedic medical practitioners for almost 3000 years.
                                                    Brahmi is rich in antioxidants with anti-inflammatory
                                                    properties. helps calm the mind, promote clarity of
                                                    thought, and memory consolidation.
                                                </p>
                                            </div>
                                        </Collapse>
                                    </div>
                                    <div className="col-12 text-right read-more-button">
                                        <a
                                            href="javascript:void(0)"
                                            className="read-more"
                                            onClick={() => handleToggle('brahmi')}
                                            aria-expanded={open.brahmi}
                                            aria-controls="brahmi-text"
                                        >
                                            {
                                                open.brahmi ? "Read Less" : "Read More"
                                            }

                                            {
                                                !open.brahmi ? <MdKeyboardDoubleArrowDown size={15} /> : <MdKeyboardDoubleArrowUp size={15} />
                                            }
                                        </a>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-12 ingredient-card">
                        <Card>
                            <Card.Body className="blue-card">
                                <div className="row">
                                    <div className="col-4 text-center my-auto">
                                        <Image src="assets/shankarpushpi.webp" className="img-fluid" alt="shankarpushpi" loading="lazy" width="100%" />
                                    </div>
                                    <div className="col-8">
                                        <h2>Shankapushpi </h2>
                                        <ul>
                                            <li>Memory Boosting</li>
                                            <li>HerbsMild relaxan</li>
                                            <li>Calmer mind</li>
                                        </ul>
                                    </div>
                                    <div className="col-12">
                                        <Collapse in={open.shankapushpi}>
                                            <div id="shankapushpi-text">
                                                <p>
                                                    Shankhpushpi, commonly known as Morning glory, is a mild
                                                    relaxant with the generic name Convolvulus pluricaulis. It
                                                    helps to quiet the brain and ease tension and anxiety. It
                                                    enhances mental wellness and may aid in the treatment of
                                                    depression due to its antidepressant effect. with added
                                                    cognitive benefits such as improved memory function and
                                                    strong attention.
                                                </p>
                                            </div>
                                        </Collapse>
                                    </div>
                                    <div className="col-12 text-right read-more-button">
                                        <a
                                            href="javascript:void(0)"
                                            className="read-more"
                                            onClick={() => handleToggle('shankapushpi')}
                                            aria-expanded={open.shankapushpi}
                                            aria-controls="shankapushpi-text"
                                        >
                                            {
                                                open.shankapushpi ? "Read Less" : "Read More"
                                            }

                                            {
                                                !open.shankapushpi ? <MdKeyboardDoubleArrowDown size={15} /> : <MdKeyboardDoubleArrowUp size={15} />
                                            }
                                        </a>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-12 ingredient-card">
                        <Card>
                            <Card.Body className="purple-card">
                                <div className="row">
                                    <div className="col-4 text-center my-auto">
                                        <Image src="assets/lavender.webp" className="img-fluid" alt="lavender" loading="lazy" width="100%" />
                                    </div>
                                    <div className="col-8">
                                        <h2>Lavender</h2>
                                        <ul>
                                            <li>
                                                In ancient times, lavender was used as a holy herb.
                                                Today, lavender is more than just a fragrant plant. As
                                                it turns out, this herb is also commonly used for
                                                medicinal and therapeutic benefits.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-12">
                                        <Collapse in={open.lavender}>
                                            <div id="lavender-text">
                                                <p>
                                                    The essence of Lavender, a natural sedative, helps in
                                                    keeping your body and mind calm. According to Ayurveda,
                                                    Vata governs all the movements and actions of the body and
                                                    nervous system respectively. Anxiety is mainly caused due
                                                    to an imbalance of Vata. Lavender helps to balance Vata
                                                    and gives a calming effect to the nervous system. It
                                                    increases your time spent in deep sleep, and increases
                                                    sleep quality.
                                                </p>
                                            </div>
                                        </Collapse>
                                    </div>
                                    <div className="col-12 text-right read-more-button">
                                        <a
                                            href="javascript:void(0)"
                                            className="read-more"
                                            onClick={() => handleToggle('lavender')}
                                            aria-expanded={open.lavender}
                                            aria-controls="lavender-text"
                                        >
                                            {
                                                open.lavender ? "Read Less" : "Read More"
                                            }

                                            {
                                                !open.lavender ? <MdKeyboardDoubleArrowDown size={15} /> : <MdKeyboardDoubleArrowUp size={15} />
                                            }
                                        </a>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-12 ingredient-card">
                        <Card>
                            <Card.Body className="purple-card">
                                <div className="row">
                                    <div className="col-4 text-center my-auto">
                                        <Image src="assets/chamomile.webp" className="img-fluid" alt="chamomile" loading="lazy" width="100%" />
                                    </div>
                                    <div className="col-8">
                                        <h2>Chamomile</h2>
                                        <ul>
                                            <li>
                                                Chamomile tea is widely thought to help people relax and
                                                fall asleep. Chamomile is an herb that comes from the
                                                daisy-like flowers of the A-teraceae plant family.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-12">
                                        <Collapse in={open.chamomile}>
                                            <div id="chamomile-text">
                                                <p>
                                                    It has been consumed for centuries as a natural remedy for
                                                    several health conditions. It contains apigenin, an
                                                    antioxidant that binds to certain receptors in your brain
                                                    that may promote sleepiness and reduce insomnia, or the
                                                    chronic inability to sleep Lower depression Better sleep
                                                    Relieves Depression & Anxiety.
                                                </p>
                                            </div>
                                        </Collapse>
                                    </div>
                                    <div className="col-12 text-right read-more-button">
                                        <a
                                            href="javascript:void(0)"
                                            className="read-more"
                                            onClick={() => handleToggle('chamomile')}
                                            aria-expanded={open.chamomile}
                                            aria-controls="chamomile-text"
                                        >
                                            {
                                                open.chamomile ? "Read Less" : "Read More"
                                            }

                                            {
                                                !open.chamomile ? <MdKeyboardDoubleArrowDown size={15} /> : <MdKeyboardDoubleArrowUp size={15} />
                                            }
                                        </a>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="col-12 ingredient-card">
                        <Card>
                            <Card.Body className="purple-card">
                                <div className="row">
                                    <div className="col-4 text-center my-auto">
                                        <Image src="assets/melatonin.webp" className="img-fluid" alt="melatonin" loading="lazy" width="100%" />
                                    </div>
                                    <div className="col-8">
                                        <h2>Melatonin</h2>
                                        <ul>
                                            <li>
                                                Melatonin is often called the sleep hormone — and for
                                                good reason. Melatonin is a hormone produced naturally
                                                by the pineal gland in the brain that helps regulate
                                                sleep-wake cycles.
                                            </li>
                                        </ul>
                                        <p className="d-none d-md-block">
                                            Research suggests that melatonin supplements may help
                                            improve sleep quality, especially in people with insomnia
                                            or other sleep disorders. Helps regulate sleep-wake
                                            cycles:May improve sleep quality:May have antioxidant
                                            properties:May have anti-inflammatory effects:{" "}
                                        </p>
                                    </div>
                                    <div className="col-12">
                                        <Collapse in={open.melatonin}>
                                            <div id="melatonin-text">
                                                <p className="d-md-none">
                                                    Research suggests that melatonin supplements may help
                                                    improve sleep quality, especially in people with insomnia
                                                    or other sleep disorders. Helps regulate sleep-wake
                                                    cycles:May improve sleep quality:May have antioxidant
                                                    properties:May have anti-inflammatory effects:
                                                </p>
                                            </div>
                                        </Collapse>
                                    </div>
                                    <div className="col-12 text-right read-more-button">
                                        <a
                                            href="javascript:void(0)"
                                            className="read-more"
                                            onClick={() => handleToggle('melatonin')}
                                            aria-expanded={open.melatonin}
                                            aria-controls="melatonin-text"
                                        >
                                            {
                                                open.melatonin ? "Read Less" : "Read More"
                                            }

                                            {
                                                !open.melatonin ? <MdKeyboardDoubleArrowDown size={15} /> : <MdKeyboardDoubleArrowUp size={15} />
                                            }
                                        </a>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <div className="row ingredients d-none d-md-flex mx-5">
                    <div className="col-12 ingredient-card">
                        <div className="card">
                            <div className="card-body pink-card">
                                <div className="row">
                                    <div className="col-6 col-xl-4 text-center">
                                        <img
                                            src="assets/affron.webp"
                                            className="affron-img"
                                            alt="affron" loading="lazy" width="100%"
                                        />
                                        <ul>
                                            <li>Maintains positive mood</li>
                                            <li>Induces relaxation</li>
                                            <li>Alleviate anxiety, Stress & tension reduction</li>
                                            <li>Improves sleep quality</li>
                                        </ul>
                                    </div>
                                    <div className="col-6 col-xl-8">
                                        <h2>Affron</h2>
                                        <p>
                                            <p>
                                                Affron is a botanical extract from saffron. Saffron,
                                                besides being a spice used for more than 3500 years, has
                                                its deep roots in ancient ayurveda & has been used for
                                                centuries, It is believed to have a range of health
                                                benefits due to its active ingredients, which include
                                                crocin, crocetin, and safranal. And today is being
                                                highly researched, uncovering various benefits. Affron®
                                                is a premium saffron ingredient standardised to 3.5%
                                                Lepticrosalides®, by HPLC, a complex of bioactive
                                                compounds responsible for the beneficial properties and
                                                organoleptic properties of affron®* It is backed by 8
                                                human clinical studies that have shown to improve mood
                                                in healthy consumers with occasional stress and anxiety,
                                                and to improve sleeplessness*Why affron®?USA
                                                Award-winner Ingredient for Cognitive Health 2020.8
                                                clinical studies published*.Proprietary extraction
                                                process AFF®ON Cool-Tech, which uniquely concentrates
                                                and preserves the bioactives with lower energy
                                                use.Genuine Crocus sativus L. Made in Spain.Lowest
                                                dosage: 28 mg/day.Unique dose-response study in 128
                                                healthy adults*.Clinically studied in adolescents*.
                                            </p>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 ingredient-card">
                        <div className="card">
                            <div className="card-body blue-card">
                                <div className="row">
                                    <div className="col-4 text-center">
                                        <img
                                            src="assets/brahmi.webp"
                                            className="ing-img img-fluid"
                                            alt="brahmi" loading="lazy" width="100%"
                                        />
                                    </div>
                                    <div className="col-8">
                                        <ul>
                                            <li>Reduction of stress through</li>
                                            <li> Retention, creation, and </li>
                                            <li>Retrieval of memory</li>
                                            <li>Sound sleep</li>
                                            <li>Reduction of anxiety</li>
                                        </ul>
                                    </div>
                                    <div className="col-12">
                                        <h2>Brahmi</h2>
                                    </div>
                                    <div className="col-12">
                                        <p>
                                            Brahmi is largely treasured as a revitalising herb used by
                                            Ayurvedic medical practitioners for almost 3000 years.
                                            Brahmi is rich in antioxidants with anti-inflammatory
                                            properties. helps calm the mind, promote clarity of
                                            thought, and memory consolidation.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 ingredient-card">
                        <div className="card">
                            <div className="card-body blue-card">
                                <div className="row">
                                    <div className="col-4 text-center">
                                        <img src="assets/shankarpushpi.webp" className="ing-img img-fluid" alt="brahmi" loading="lazy" width="100%" />
                                    </div>
                                    <div className="col-8">
                                        <ul>
                                            <li>Memory Boosting</li>
                                            <li>HerbsMild relaxan</li>
                                            <li>Calmer mind</li>
                                            <li className="invisible d-none d-lg-block d-xl-none">asd</li>
                                        </ul>
                                    </div>
                                    <div className="col-12">
                                        <h2>Shankapushpi </h2>
                                    </div>
                                    <div className="col-12">
                                        <p>Shankhpushpi, commonly known as Morning glory, is a mild relaxant with the generic name Convolvulus pluricaulis. It helps to quiet the brain and ease tension and anxiety. It enhances mental wellness and may aid in the treatment of depression due to its antidepressant effect. with added cognitive benefits such as improved memory function and strong attention.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4 ingredient-card">
                        <div className="card mini-card">
                            <div className="card-body purple-card">
                                <div className="row text-center">
                                    <div className="col-12">
                                        <img src="assets/lavender.webp" className="ing-img img-fluid" alt="pentagon-shape" loading="lazy" width="100%" />
                                    </div>
                                    <div className="col-12">
                                        <h2>Lavender</h2>
                                    </div>
                                    <div className="col-12">
                                        <p>In ancient times, lavender was used as a holy herb. Today, lavender is more than just a fragrant plant. As it turns out, this herb is also commonly used for medicinal and therapeutic benefits.  The essence of Lavender, a natural sedative, helps in keeping your body and mind calm. According to Ayurveda, Vata governs all the movements and actions of the body and nervous system respectively. Anxiety is mainly caused due to an imbalance of Vata. Lavender helps to balance Vata and gives a calming effect to the nervous system. It increases your time spent in deep sleep, and increases sleep quality.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4 ingredient-card">
                        <div className="card mini-card">
                            <div className="card-body purple-card">
                                <div className="row text-center">
                                    <div className="col-12">
                                        <img src="assets/chamomile.webp" className="ing-img img-fluid" alt="pentagon-shape" loading="lazy" width="100%" />
                                    </div>
                                    <div className="col-12">
                                        <h2>Chamomile</h2>
                                    </div>
                                    <div className="col-12">
                                        <p>Chamomile tea is widely thought to help people relax and fall asleep. Chamomile is an herb that comes from the daisy-like flowers of the
                                            A-teraceae plant family. It has been consumed for centuries as a natural remedy for several health conditions. It contains apigenin, an antioxidant that binds to certain receptors in your brain that may promote sleepiness and reduce insomnia, or the chronic inability to sleep Lower depression Better sleep Relieves Depression & Anxiety</p>
                                        <br /><br /><br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4 ingredient-card">
                        <div className="card mini-card">
                            <div className="card-body purple-card">
                                <div className="row text-center">
                                    <div className="col-12">
                                        <img src="assets/melatonin.webp" className="ing-img img-fluid" alt="pentagon-shape" loading="lazy" width="100%" />
                                    </div>
                                    <div className="col-12">
                                        <h2>Melatonin</h2>
                                    </div>
                                    <div className="col-12">
                                        <p> Melatonin is often called the sleep hormone — and for good reason. Melatonin is a hormone produced naturally by the pineal gland in the brain that helps regulate sleep-wake cycles. Research suggests that melatonin supplements may help improve sleep quality, especially in people with insomnia or other sleep disorders. Helps regulate sleep-wake cycles:May improve sleep quality:May have antioxidant properties:May have anti-inflammatory effects: </p>
                                        <br /><br /><br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
});

export default HowItWorksSection;
