import React, { useState } from 'react'
import { Collapse } from 'react-bootstrap';
import './FAQSection.css'


const FAQSection = () => {
    const [showAddiction, setShowAddiction] = useState(false);
    const [showSideEffects, setShowSideEffects] = useState(false);
    const [showEffectTime, setShowEffectTime] = useState(false);
    const [showPrescription, setShowPrescription] = useState(false);
    const [showStore, setShowStore] = useState(false);
    return (
        <section className="faq-section" id="faq">
            <div className="row faq-row text-center">
                <div className="col-12 bg-heading-green">
                    <h1>FAQ</h1>
                </div>
                <div className="col-12 bg-blue">
                    <p
                        onClick={() => setShowAddiction(!showAddiction)}
                        aria-controls="addiction"
                        aria-expanded={showAddiction}
                        role="button"
                    >
                        Can I become addicted to sleeping gummies?
                    </p>
                    <Collapse in={showAddiction}>
                        <div id="addiction">
                            <p className="pt-3">
                                Sleeping gummies are not habit-forming in the way that prescription sleeping pills can be.
                            </p>
                        </div>
                    </Collapse>
                </div>
                <div className="col-12 bg-green">
                    <p
                        onClick={() => setShowSideEffects(!showSideEffects)}
                        aria-controls="side-effects"
                        aria-expanded={showSideEffects}
                        role="button"
                    >
                        Are there any side effects of sleeping gummies?
                    </p>
                    <Collapse in={showSideEffects}>
                        <div id="side-effects">
                            <p className="pt-3">No, there are no side effects of sleeping gummies.</p>
                        </div>
                    </Collapse>
                </div>
                <div className="col-12 bg-blue">
                    <p
                        onClick={() => setShowEffectTime(!showEffectTime)}
                        aria-controls="effect-time"
                        aria-expanded={showEffectTime}
                        role="button"
                    >
                        How long does it take for sleeping gummies to work?
                    </p>
                    <Collapse in={showEffectTime}>
                        <div id="effect-time">
                            <p className="pt-3">
                                The time it takes for sleeping gummies to work can vary depending on the individual. Some people may
                                experience the effects within 30 minutes, while others may take longer.
                            </p>
                        </div>
                    </Collapse>
                </div>
                <div className="col-12 bg-green">
                    <p
                        onClick={() => setShowPrescription(!showPrescription)}
                        aria-controls="prescription"
                        aria-expanded={showPrescription}
                        role="button"
                    >
                        Do I need a prescription to buy sleeping gummies?
                    </p>
                    <Collapse in={showPrescription}>
                        <div id="prescription">
                            <p className="pt-3">No, sleeping gummies are a dietary supplement and do not require a prescription.</p>
                        </div>
                    </Collapse>
                </div>
                <div className="col-12 bg-blue">
                    <p
                        onClick={() => setShowStore(!showStore)}
                        aria-controls="store"
                        aria-expanded={showStore}
                        role="button"
                    >
                        How should I store sleeping gummies?
                    </p>
                    <Collapse in={showStore}>
                        <div id="store">
                            <p className="pt-3">Sleeping gummies should be stored in a cool, dry place.</p>
                        </div>
                    </Collapse>
                </div>
            </div>


        </section>
    )
}

export default FAQSection
