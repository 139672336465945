import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./components/footer/Footer";
import NavbarComponent from "./components/navbar/Navbar";

const AppLayout = () => {
  return (
    <>
      <div>
        <NavbarComponent />
      </div>
      <div>{<Outlet />}</div>
      <div>
        <Footer />
      </div>
     </>
  );
};

export default AppLayout;
