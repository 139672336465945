import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./SecondBuyNow.css";
import { useQuery } from "react-query";
import axios from "../../api/axios";
import { toast } from "react-toastify";

const getAllProductsData = async () => {
  const accessToken = localStorage.getItem("access_token");
  const headers = {
    Authorization: accessToken,
  };
  const response = await axios.get("products", { headers });
  return response.data;
};

const SecondBuyNow = () => {
  const navigate = useNavigate();
  const [showBanner, setShowBanner] = useState(false);
  const [productsData, setProductsData] = useState([]);

  const navigateToBuyNow = () => {
    navigate("/sleepzzz-pack-of-1");
  };

  const onSuccess = (data) => {
    const products = data?.data?.products
    setProductsData(products);
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "view_item_list",
      ecommerce: {
        item_list_id: "uplife_products",
        item_list_name: "Up life products",
        items: products?.map((item, index) => ({
          item_id: item?.id,
          item_name: item?.product_name,
          affiliation: "UP LIFE",

          index: index,
          item_brand: "UP LIFE",
          item_variant: item?.variant_title,
          price: item?.offer_price,
        }))
      }
    });
  }
  const onError = (error) => {
    if (error.response && error.response?.data?.status === false) {
      let errorMessage = error.response.data.error;
      toast.error(errorMessage, {
        position: "bottom-center",
        autoClose: 3000,
        theme: "colored",
      });
    }
  }

  const { data, isError, isLoading } = useQuery({
    queryKey: 'productsList',
    queryFn: getAllProductsData,
    onSuccess,
    onError
  })

  useEffect(() => {
    const debounce = (func, wait) => {
      let timeout;
      return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), wait);
      };
    };

    const handleScroll = debounce(() => {
      const scrollY = window.scrollY || window.pageYOffset;
      const threshold = window.innerHeight;
      setShowBanner(scrollY > threshold);
    }, 100);

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <section>
        <div className="d-flex mb-md-5 p-md-1">
          <div className="w-50 pt-5 left-div">
            <h2>Your Deep Sleep is Just a Gummie Away</h2>
            <div className="mt-3 d-md-block d-lg-block d-none">
              <img
                src="assets/money-back-garuntee.webp"
                className="w-100"
                alt="money-back-garuntee"
                loading="lazy"
                width="100%"
              />
            </div>
            <div className="d-flex align-items-center gap-md-3 gap-sm-1 mt-2 money-guarantee d-md-flex d-lg-flex d-none">
              <div className="bottom-price" style={{ position: "relative" }}>
                <h2 className="price">
                  ₹
                  <span className="original-price">
                    {productsData[0]?.mrp}
                  </span>{" "}
                  <span className="offer-price">{productsData[0]?.offer_price}</span>
                </h2>
              </div>
              <div className="p-0 btn-div">
                <button
                  className="btn btn-sm buy-btn w-100"
                  onClick={navigateToBuyNow}
                >
                  BUY NOW
                </button>
              </div>
            </div>
          </div>

          <div className="w-50 right-div">
            <img
              src="assets/bottle-front-image-main.webp"
              className="bottle"
              alt="bottle-front-image-main"
              loading="lazy"
            />
          </div>
        </div>

        <div className="d-md-none d-lg-none d-block p-2">
          <div className="mt-3">
            <img
              src="assets/money-back-garuntee.webp"
              className="w-100"
              alt="money-back-garuntee"
              loading="lazy"
              width="100%"
            />
          </div>
          <div className="d-flex align-items-center gap-md-3 gap-sm-1 mt-2 money-guarantee">
            <div className="bottom-price" style={{ position: "relative" }}>
              <h2 className="price">
                ₹
                <span className="original-price">
                {productsData[0]?.mrp}
                </span>{" "}
                <span className="offer-price">{productsData[0]?.offer_price}</span>
              </h2>
            </div>
            <div className="p-0 btn-div">
              <button
                className="btn btn-sm buy-btn w-100"
                onClick={navigateToBuyNow}
              >
                BUY NOW
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className={`buy-now-desktop-banner ${showBanner ? 'visible' : 'hidden'}`}>
        <div className="">
          <div className="row">
            <div className="col-md-3 col-lg-3 offset-lg-1 text-center">
              <img src="./assets/uplife_sleepzzz.webp" className="bottle-img d-block" alt="uplife_sleepzzz" loading="lazy" />
            </div>
            <div className="col-md-5 col-lg-4 text-center my-auto money-back-garuntee-div ">
              <img src="./assets/money-back-garuntee.webp" className="offer-img d-block w-100" width='300px' alt="money-back-garuntee" loading="lazy" />
            </div>
            <div className='d-flex col-md-4 align-items-center gap-md-3 gap-sm-1 mt-2  money-guarantee d-lg-block d-md-block d-none '>
              <div className="bottom-price mx-md-5 mx-lg-4" style={{ position: 'relative' }}>
                <h2 className="price">₹<span className="original-price">{productsData[0]?.mrp}</span> <span className="offer-price">{productsData[0]?.offer_price}</span></h2>
              </div>
              <div className="p-0 btn-div">
                <button className="btn btn-sm buy-btn w-100" onClick={navigateToBuyNow}>BUY NOW</button>
              </div>
            </div>

            <div className='d-md-none d-lg-none d-block p-2'>
              <div className='d-flex align-items-center justify-content-end gap-md-3 gap-sm-1 mt-2 money-guarantee'>
                <div className="bottom-price" style={{ position: 'relative' }} >
                  <h2 className="price">₹ <span className="original-price">{productsData[0]?.mrp}</span> <span className="offer-price">{productsData[0]?.offer_price}</span></h2>
                </div>
                <div className="p-0 btn-div" style={{ width: '100px' }}>
                  <button className="btn btn-sm buy-btn w-100" onClick={navigateToBuyNow}>BUY NOW</button>
                </div>
              </div>
            </div>

            <div className='row d-lg-none d-md-none d-flex'>
              <div className="col-4 offset-lg-1 text-center">
                <img src="./assets/uplife_sleepzzz.webp" className="bottle-img d-block" alt="uplife_sleepzzz" loading="lazy" />
              </div>
              <div className='col-4'>
                <div className='col-md-4 gap-md-3 gap-sm-1 mt-2  money-guarantee d-md-flex d-lg-flex d-none'>
                  <div className="bottom-price" style={{ position: 'relative' }}>
                    <h2 className="price">₹ <span className="original-price"> {productsData[0]?.mrp}</span> <span className="offer-price">{productsData[0]?.offer_price}</span></h2>
                  </div>
                  <div className="p-0 btn-div">
                    <button className="btn btn-sm buy-btn w-100" onClick={navigateToBuyNow}>BUY NOW</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SecondBuyNow;
