import { combineReducers } from "redux";
import userReducer from './userSlice';
import cartReducer from './cartSlice';
import addressReducer from "./addressSlice";
import orderReducer from "./orderSlice";

const rootReducer = combineReducers({
    user: userReducer,
    cart: cartReducer,
    address: addressReducer,
    order: orderReducer
})

export default rootReducer;